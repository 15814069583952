export enum CompoundInterval {
    daily = 365,
    semiWeekly = 104,
    weekly = 52,
    biWeekly = 26,
    semiMonthly = 24,
    monthly = 12,
    biMonthly = 6,
    quarterly = 4,
    halfYearly = 2,
    yearly = 1,
  }

export function calculateCompoundInterest(
    principal: number,
    interestRate: number,
    compoundInterval: CompoundInterval,
    timeInYears: number
  ) {
    //A = P(1+r/n)^nt
    return (
      principal *
      (1 + interestRate / compoundInterval) ** (compoundInterval * timeInYears)
    );
  }
  