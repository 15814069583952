import {
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  TextField,
  styled,
} from "@mui/material";
import React from "react";

interface CustomInputProperties {
  label: string;
  value: any | undefined;
  onChange?: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
  children?: React.ReactNode;
}

export function CustomSelect(properties: CustomInputProperties) {
  return (
    <FormControl
      fullWidth
      sx={{
        "& label.Mui-focused": {
          color: "secondary.main",
        },
      }}
    >
      <InputLabel id="demo-simple-select-label">{properties.label}</InputLabel>
      <Select
        variant="outlined"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={properties.value}
        label={properties.label}
        sx={{
          color: "info.main",
          "& .MuiSvgIcon-root": {
            color: "info.main",
          },
        }}
        onChange={properties.onChange}
      >
        {properties.children}
      </Select>
    </FormControl>
  );
}
