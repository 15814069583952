import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { container } from "tsyringe";
import { RootState } from "../../app/store";
import { AnalyticsService } from "../../services/analytics";
import { calculateCompoundInterest, CompoundInterval } from "../../utilities/finance";
import { round } from "../../utilities/maths";

export interface CreditCardRepaymentCalculatorState {
  currency: string;
  creditCardBalance: number;
  interestRatePercentage: number;
  monthlyRepaymentAmount: number;
  resultMonthsToPayOffDebt: number;
  resultMonthsToPayOffDebtCopied: boolean;
  resultTotalPayBack: number;
  resultTotalPayBackCopied: boolean;
  resultTotalInterestPaid: number;
  resultTotalInterestPaidCopied: boolean;
  compoundInterestFormulaCopied: boolean;
  showInstallmentLowerThanCreditError: boolean;
}

const initialState: CreditCardRepaymentCalculatorState = {
  currency: "$",
  creditCardBalance: 1000,
  interestRatePercentage: 12,
  monthlyRepaymentAmount: 100,
  resultMonthsToPayOffDebt: 11,
  resultMonthsToPayOffDebtCopied: false,
  resultTotalPayBack: 1058.98,
  resultTotalPayBackCopied: false,
  resultTotalInterestPaid: 58.98,
  resultTotalInterestPaidCopied: false,
  compoundInterestFormulaCopied: false,
  showInstallmentLowerThanCreditError: false,
};

export const CreditCardRepaymentCalculatorSlice = createSlice({
  name: "creditCardRepaymentCalculator",
  initialState,
  reducers: {
    onCurrencyChanged: (state, action: PayloadAction<string>) => {
      state.currency = action.payload;
    },
    onCreditCardBalanceChanged: (state, action: PayloadAction<number>) => {
      if(Number.isNaN(action.payload))
      {
        return;
      }
      
      state.creditCardBalance = action.payload;
    },
    onInterestRateChanged: (state, action: PayloadAction<number>) => {
      if(Number.isNaN(action.payload))
      {
        return;
      }

      state.interestRatePercentage = action.payload;
    },
    onMonthlyRepaymentAmountChanged: (state, action: PayloadAction<number>) => {
      if(Number.isNaN(action.payload))
      {
        return;
      }

      state.monthlyRepaymentAmount = action.payload;
    },
    onResultMonthsToPayOffDebtCopiedTapped: (state) => {
        state.resultMonthsToPayOffDebtCopied = true;
        state.resultTotalPayBackCopied = false;
        state.resultTotalInterestPaidCopied = false;
        state.compoundInterestFormulaCopied = false;
    },
    onResultTotalPayBackCopiedTapped: (state) => {
        state.resultMonthsToPayOffDebtCopied = false;
        state.resultTotalPayBackCopied = true;
        state.resultTotalInterestPaidCopied = false;
        state.compoundInterestFormulaCopied = false;
    },
    onResultTotalInterestPaidCopiedTapped: (state) => {
        state.resultMonthsToPayOffDebtCopied = false;
        state.resultTotalPayBackCopied = false;
        state.resultTotalInterestPaidCopied = true;
        state.compoundInterestFormulaCopied = false;
    },
    onCompoundInterestFormulaCopiedTapped: (state) => {
      state.resultMonthsToPayOffDebtCopied = false;
      state.resultTotalPayBackCopied = false;
      state.resultTotalInterestPaidCopied = false;
      state.compoundInterestFormulaCopied = true;
  },
    calculateTapped: (state) => {
        const analyticsService = container.resolve(AnalyticsService);
        analyticsService.LogEvent({    
        category: 'Button Click',
        action: 'Convert Clicked',
        label: 'Credit Card Repayment Calculator',
        });
        var negativeCreditCardBalance = state.creditCardBalance > 0 ? state.creditCardBalance * -1 : state.creditCardBalance;
        var remainingCreditBalance = negativeCreditCardBalance;
        var interestRate = state.interestRatePercentage/100;
        var numberOfMonths = 0;
        var totalPayBack = 0;

        state.resultMonthsToPayOffDebtCopied = false;
        state.resultTotalPayBackCopied = false;
        state.resultTotalInterestPaidCopied = false;
        state.showInstallmentLowerThanCreditError = false;
        state.compoundInterestFormulaCopied = false;

        var singleMonthsInterest = calculateCompoundInterest(
            negativeCreditCardBalance,
            interestRate,
            CompoundInterval.monthly,
            1/12
          ) - negativeCreditCardBalance;

        if(singleMonthsInterest *-1 >= state.monthlyRepaymentAmount)
        {
            state.showInstallmentLowerThanCreditError = true;
            console.log('Single month\'s interest is higher than monthly installment');
            return;
        }

        while(round(remainingCreditBalance,2) < 0) {
            remainingCreditBalance = calculateCompoundInterest(
                remainingCreditBalance,
                interestRate,
                CompoundInterval.monthly,
                1/12
              );

              var monthlyContribution = remainingCreditBalance * -1 <= state.monthlyRepaymentAmount ? remainingCreditBalance * -1 : state.monthlyRepaymentAmount;   
              totalPayBack += monthlyContribution;
              remainingCreditBalance += monthlyContribution;

              numberOfMonths++;
        }

        state.resultMonthsToPayOffDebt = numberOfMonths;
        state.resultTotalPayBack = round(totalPayBack,2); 
        state.resultTotalInterestPaid = round(totalPayBack + negativeCreditCardBalance,2);

    },
  },
});

export const {
  onCurrencyChanged,
  onCreditCardBalanceChanged,
  onInterestRateChanged,
  onMonthlyRepaymentAmountChanged,
  onResultMonthsToPayOffDebtCopiedTapped,
  onResultTotalPayBackCopiedTapped,
  onResultTotalInterestPaidCopiedTapped,
  onCompoundInterestFormulaCopiedTapped,
  calculateTapped,
} = CreditCardRepaymentCalculatorSlice.actions;

export const selectState = (state: RootState) =>
  state.creditCardRepaymentCalculator;

export default CreditCardRepaymentCalculatorSlice.reducer;
