import React, { ReactNode } from "react";
import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import { Helmet } from "react-helmet";
import { SideMenu } from "../../features/side_menu/SideMenu";
import { ScrollRestoration } from "react-router-dom";

export class PageContainer extends React.Component<{
  pageTitle: string;
  pageDescription: string;
  pageRelativeUrl: string;
  children?: React.ReactNode;
}> {
  render() {
    const canonicalUrl = `https://numberchomper.com/${this.props.pageRelativeUrl}`;
    return (
        <Layout>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              m: 0,
              p: 0,
            }}
          >
            <Helmet>
              <title>{this.props.pageTitle}</title>
              <meta name="description" content={this.props.pageDescription} />
              <link rel="canonical" href={canonicalUrl} />

              {/* Open graph, social media sharing tags: */}
              <meta property="og:type" content="website" />
              <meta property="og:title" content={this.props.pageTitle} />
              <meta
                property="og:description"
                content={this.props.pageDescription}
              />
              <meta property="og:site_name" content="Number Chomper" />
              <meta
                property="og:image"
                content="https://numberchomper.com/favicon-512x512.png"
              />

              {/* Twitter Card */}
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={this.props.pageTitle} />
              <meta
                name="twitter:description"
                content={this.props.pageDescription}
              />
              <meta
                name="twitter:image"
                content="https://numberchomper.com/favicon-512x512.png"
              />
            </Helmet>
            {this.props.children}
            <ScrollRestoration/>
          </Box>
        </Layout>
    );
  }
}

interface LayoutProps {
  children: ReactNode;
}

function Layout(props: LayoutProps) {
  const { children } = props;
  const theme = useTheme();
  const lessThanSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container maxWidth="sm" disableGutters={lessThanSm}>
      {children}
    </Container>
  );
}
