import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  codeSnippetCopied,
  pngToFaviconAsync,
  selectState,
} from "./FaviconGeneratorSlice";
import { Button, IconButton, Stack, Typography, styled } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box } from "@mui/system";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import js from "react-syntax-highlighter/dist/esm/languages/hljs/xml";
import docco from "react-syntax-highlighter/dist/esm/styles/hljs/androidstudio";
import CopyIcon from "@mui/icons-material/ContentCopy";
import TaskAlt from "@mui/icons-material/TaskAlt";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { SideMenuPageContainer } from "../../components/side_menu_page_container/SideMenuPageContainer";
import { HomeCard } from "../../components/home_card/HomeCard";
import AndroidIosImageResizerImage from "../../assets/AndroidIosImageResizer.webp";

SyntaxHighlighter.registerLanguage("xml", js);

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export function FaviconGenerator() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectState);

  return (
    <SideMenuPageContainer
      pageTitle="Favicon Generator"
      pageRelativeUrl="FaviconGenerator"
      pageDescription="Generate a Website favicon and manifest.json for multiple resolutions to achieve maximum browser support"
    >
      <Box sx={{ width: { xs: 300, sm: 350, md: 600, lg: 700 } }}>
        <Grid2
          container
          spacing={2}
          sx={{ mt: 2 }}
          columns={{ xs: 6, sm: 6, md: 12, lg: 12 }}
        >
          <Grid2 xs={12}>
            <Typography variant="h2" sx={{ mb: 2 }}>
              Create a Favicon for your website with maximum device support
            </Typography>
          </Grid2>
          <Grid2 xs={12}>
            <Typography variant="body1" sx={{ mb: 0.5 }}>
              <b style={{ color: "#FFD9A8" }}>Step 1:</b> Upload PNG to generate
              favicon images <br />
              (Download will start automatically once favicon has finished
              generating):
            </Typography>
          </Grid2>
          <Grid2 xs={12}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon sx={{ color: "#FFD9A8" }} />}
                onChange={(e: any) =>
                  dispatch(pngToFaviconAsync(e.target.files[0]))
                }
              >
                <Typography>Upload PNG</Typography>
                <VisuallyHiddenInput type="file" accept="image/png" />
              </Button>
            </Stack>
          </Grid2>
          <Grid2 xs={12}>
            <Box sx={{ mt: 2 }} />
            <Typography variant="body1">
              <b style={{ color: "#FFD9A8" }}>Step 2:</b> Add this code to your
              html &lt;head&gt; tag:
            </Typography>
          </Grid2>
          <Grid2 xs={12}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Box sx={{ width: { xs: 300, sm: 350, md: 600, lg: 700 } }}>
                <Box
                  sx={{
                    mb: -1.8,
                    bgcolor: "#282B2E",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="helptext" sx={{ ml: 1, pt: 1, pb: 1 }}>
                    HTML
                  </Typography>
                  <IconButton
                    aria-label="copy"
                    sx={{ color: "#A5D9EE" }}
                    onClick={() => {
                      navigator.clipboard.writeText(faviconHtmlHeaderString);
                      dispatch(codeSnippetCopied(true));
                    }}
                  >
                    {state.codesnippetCopied ? (
                      <TaskAlt sx={{ color: "#97e286" }} />
                    ) : (
                      <CopyIcon />
                    )}
                  </IconButton>
                </Box>
                <SyntaxHighlighter language="xml" style={docco}>
                  {faviconHtmlHeaderString}
                </SyntaxHighlighter>
              </Box>
            </Stack>
          </Grid2>
          <Grid2 xs={12}>
            <Typography variant="h2" sx={{ mb: 3, mt: 5 }}>
              What are the recommended formats and resolutions for favicons in
              2024?
            </Typography>
            <Typography variant="h3" sx={{ mb: 2 }}>
              Is .ico still recommended?
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              According to{" "}
              <a href="https://www.w3.org/2005/10/howto-favicon">
                a 2005 post on www.w3.org
              </a>
              : "the format for the image you have chosen must be 16x16 pixels
              or 32x32 pixels, using either 8-bit or 24-bit colors." (although
              32-bit color support has been added since). The .ico format,
              supporting multiple resolutions in a single file, has become a
              standard for favicons. Modern react sample apps typically still
              include a sample .ico file with resolutions of 16x16, 24x24,
              32x32, and 64x64 pixels. Although .png format support is
              widespread in modern browsers (for instance,{" "}
              <a href="https://www-archive.mozilla.org/releases/mozilla0.9.6/">
                Firefox has supported it since 2003
              </a>
              ), many websites continue to use .ico for legacy support, and it
              is still used in{" "}
              <a href="https://developers.google.com/search/docs/appearance/favicon-in-search">
                Google's example of a favicon
              </a>
              .
            </Typography>
            <Typography variant="h3" sx={{ mb: 2 }}>
              Google search and Android Chrome install as app support
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              For your favicon to display in Google searches, your favicon must
              also have resolutions which are{" "}
              <a href="https://developers.google.com/search/docs/appearance/favicon-in-search">
                multiples of 48x48 pixels
              </a>{" "}
              typically in PNG or any other supported format. Google's material
              design guidelines{" "}
              <a href="https://m2.material.io/design/iconography/product-icons.html">
                suggest designing icons at 192x192 pixels
              </a>
              . Consequently, many websites include 48x48 and 192x192 pixel PNG
              favicons to cover a wide range of support for Google search and
              Android Chrome app installation. It is important to note that even
              when following Google's guidelines exactly they might still not
              display your favicon in Google searches.
            </Typography>
            <Typography variant="h3" sx={{ mb: 2 }}>
              Apple support
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              To add support for Apple devices you need to add a link for
              favicons with rel=”apple-touch-icon” instead of rel="icon".{" "}
              <a href="https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariWebContent/ConfiguringWebApplications/ConfiguringWebApplications.html#//apple_ref/doc/uid/TP40002051-CH3-SW4">
                The recommended sizes
              </a>{" "}
              for Apple devices are 152x152 pixels for iPad (Non-retina),
              180x180 pixels for iPhone and 167x167 pixels for iPad (Retina).
            </Typography>
            <Typography variant="h3" sx={{ mb: 2 }}>
              Web app manifest
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              <a href="https://developer.mozilla.org/en-US/docs/Web/Manifest">
                A web app manifest
              </a>{" "}
              allows web apps to be installed on devices as applications. The
              icons specified in a manifest usually have higher resolutions,
              sometimes up to 512x512 pixels to accommodate for higher
              resolutions required by app icons. The manifest, a JSON file, can
              include various other parameters like background color, name, and
              description. You can see browser compatibility for icons specified
              via the web app manifest{" "}
              <a href="https://developer.mozilla.org/en-US/docs/Web/Manifest/icons#browser_compatibility">
                here.
              </a>{" "}
              *Remember to include a reference to your manifest in the HTML
              head!
            </Typography>
            <Typography variant="h3" sx={{ mb: 2 }}>
              What about SVGs?
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Not all browsers (Such as Apple's Safari browser) support using
              SVGs for favicons. It is therefore recommended to use PNGs, ICOs
              and web manifests instead.
            </Typography>
          </Grid2>
          <Grid2 xs={12}>
            <Typography variant="h3" sx={{ mt: 3, mb: 1 }}>
              Related
            </Typography>
          </Grid2>
          <Grid2 xs={6}>
              <HomeCard
                title="Android iOS Image Resizer"
                description="Resize Android and iOS app images for all screen densities by the click of a button"
                tooltip="Free and easy, PNG to Android and iOS image assets converter. "
                link="/AndroidIosImageResizer"
                smallCard={true}
                image={AndroidIosImageResizerImage}
              />
            </Grid2>
        </Grid2>
      </Box>
    </SideMenuPageContainer>
  );
}

const faviconHtmlHeaderString = `<!-- Backwards compatability -->
<link rel="icon" sizes="16x16 24x24 32x32 64x64" href="favicon.ico">

<!-- All other browsers -->
<link rel="icon" type="image/png" sizes="32x32" href="favicon-32x32.png">
<link rel="icon" type="image/png" sizes="16x16" href="favicon-16x16.png">

<!-- Google Search and Android Chrome -->
<link rel="icon" type="image/png" sizes="48x48" href="favicon-48x48.png">
<link rel="icon" type="image/png" sizes="192x192" href="favicon-192x192.png">

<!-- iPhone -->
<link rel="apple-touch-icon" type="image/png" sizes="180x180" href="favicon-180x180.png">

<!-- iPad Retina -->
<link rel="apple-touch-icon" type="image/png" sizes="167x167" href="favicon-167x167.png">

<!-- Other iPads -->
<link rel="apple-touch-icon" type="image/png" sizes="152x152" href="favicon-152x152.png">

<!-- Web application manifest -->
<link rel="manifest" href="manifest.json">`;
