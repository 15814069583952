import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { PageContainer } from "../../components/page_container/PageContainer";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { selectState } from "./SideMenuSlice";
import { useLocation } from "react-router";
import React, { useEffect } from "react";
import FunctionIcon from "@mui/icons-material/FunctionsOutlined";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeartOutlined";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import WeightIcon from "@mui/icons-material/ScaleOutlined";
import CompareIcon from "@mui/icons-material/CompareOutlined";
import HeatIcon from "@mui/icons-material/ThermostatOutlined";
import TrendingUpIcon from "@mui/icons-material/TrendingUpOutlined";
import CreditCardIcon from "@mui/icons-material/CreditCardOutlined";
import AspectRatioIcon from "@mui/icons-material/AspectRatioOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import * as changeCase from "change-case";
import packageJson from "../../../package.json";

import logo from "../../assets/LogoSmall.webp";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { SideMenuLink } from "../../components/side_menu_link/SideMenuLink";

const drawerWidth = 240;

interface SideMenuProperties {
  children?: React.ReactNode;
}

export function SideMenu(properties: SideMenuProperties) {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectState);

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  useEffect(() => {}, [location]);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  var pageTitle = changeCase.capitalCase(
    location.pathname.split("/")[location.pathname.split.length - 1]
  );
  if (pageTitle == "") {
    pageTitle = "Base Converter";
  }

  const drawer = (
    <Box>
      <Box sx={{ mt: 1 }} />
      <Link to="/">
        <img
          src={logo}
          loading="lazy"
          width={120}
          height={115}
          alt="Number Chomper logo"
          className="applogo"
          title="Free, Fast and Functional Online Converters / Generators"
        />
      </Link>
      <Divider />
      <Box sx={{ color: "#FFFFFF" }}>

        <SideMenuLink
          label="Base Converter"
          link="/BaseConverter"
          onClick={() => {
            setMobileOpen(false);
          }}
          icon={
            <FunctionIcon
            sx={{ color: "#FFFFFF", height: "27px", width: "27px" }}
          />
          }
        />

        <SideMenuLink
          label="BMI Calculator"
          link="/BmiCalculator"
          onClick={() => {
            setMobileOpen(false);
          }}
          icon={
            <MonitorHeartIcon
              sx={{ color: "#FFFFFF", height: "23px", width: "23px" }}
            />
          }
        />

        <SideMenuLink
          label="Distance Converter"
          link="/DistanceUnitConverter"
          onClick={() => {
            setMobileOpen(false);
          }}
          icon={
            <SquareFootIcon
              sx={{ color: "#FFFFFF", height: "27px", width: "27px" }}
            />
          }
        />

        <SideMenuLink
          label="Weight Converter"
          link="/WeightUnitConverter"
          onClick={() => {
            setMobileOpen(false);
          }}
          icon={
            <WeightIcon
              sx={{ color: "#FFFFFF", height: "23px", width: "23px" }}
            />
          }
        />

        <SideMenuLink
          label="Favicon Generator"
          link="/FaviconGenerator"
          onClick={() => {
            setMobileOpen(false);
          }}
          icon={
            <CompareIcon
              sx={{ color: "#FFFFFF", height: "23px", width: "23px" }}
            />
          }
        />

        <SideMenuLink
          label="Temperature Converter"
          link="/TemperatureConverter"
          onClick={() => {
            setMobileOpen(false);
          }}
          icon={
            <HeatIcon
              sx={{ color: "#FFFFFF", height: "23px", width: "23px" }}
            />
          }
        />

        <SideMenuLink
          label="Interest Calculator"
          link="/CompoundInterestCalculator"
          onClick={() => {
            setMobileOpen(false);
          }}
          icon={
            <TrendingUpIcon
              sx={{ color: "#FFFFFF", height: "23px", width: "23px" }}
            />
          }
        />

        <SideMenuLink
          label="Credit Card Repayment Calculator"
          link="/CreditCardRepaymentCalculator"
          onClick={() => {
            setMobileOpen(false);
          }}
          icon={
            <CreditCardIcon
              sx={{ color: "#FFFFFF", height: "23px", width: "23px" }}
            />
          }
        />

        <SideMenuLink
          label="Android iOS Image Resizer"
          link="/AndroidIosImageResizer"
          onClick={() => {
            setMobileOpen(false);
          }}
          icon={
            <AspectRatioIcon
              sx={{ color: "#FFFFFF", height: "23px", width: "23px" }}
            />
          }
        />
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h1" noWrap component="div">
            {pageTitle}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
          <Divider />

          <Box className="versionDiv" sx={{ mt: 2, marginTop: "auto" }}>
            <label>Version: {packageJson.version}</label>
          </Box>
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
          <Divider />

          <Box className="versionDiv" sx={{ mt: 2, marginTop: "auto" }}>
            <label>Version: {packageJson.version}</label>
          </Box>
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {properties.children}
      </Box>
    </Box>
  );
}
