import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import CalculateIcon from "@mui/icons-material/CalculateOutlined";

export class CustomButton extends React.Component<{
  label: string;
  icon?: React.ReactNode;
  width?: number | undefined
  disabled?: boolean;
  onClick: () => void;
}> {
  render() {
    return (
      <Button
        variant="contained"
        disabled={this.props.disabled}
        startIcon={this.props.icon ?? <CalculateIcon sx={{ height: "25px", width: "25px" }} />}
        sx={{ color: "#FFD9A8", verticalAlign: "center", alignItems: "center", width: this.props.width }}
        aria-label={this.props.label}
        onClick={this.props.onClick}
      >
        <Stack direction="column" justifyContent="center" alignContent="center" >
          <Typography sx={{ color: "#FFD9A8", height: "25px" }}>{this.props.label}</Typography>
        </Stack>
      </Button>
    );
  }
}
