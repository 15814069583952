import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import CssBaseline from "@mui/material/CssBaseline";
import {
  GlobalStyles,
  Theme,
  ThemeProvider,
  createTheme,
  makeStyles,
  outlinedInputClasses,
} from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    helptext: React.CSSProperties;
    menulink: React.CSSProperties;
    error: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    helptext?: React.CSSProperties;
    menulink?: React.CSSProperties;
    error?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    helptext: true;
    menulink: true;
    error: true;
  }
}
const labelFontSize = "20px";
const container = document.getElementById("root")!;
const root = createRoot(container);
const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        input {
          font-size: 20px;
          padding: 2px;
          margin: 5px;
          text-align: start;
          background-color: #23252c;
          appearance: none;
          color: #aedff2;
          border: none;
          width: 100%;
        }

        select {
          font-size: 20px;
          padding: 2px;
          margin: 5px;
          text-align: start;
          background-color: #23252c;
          appearance: none;
          color: #aedff2;
          border: none;
          -webkit-appearance: listbox !important;
          width: 100%;
        }


        a:visited {
          color: #d6c5b0;
        }

        a:link {
          color: #d6c5b0;
        }

        a:hover {
          color: #FFFFFF;
        }

        a:active { 
          color: #FFFFFF;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }
      `,
    },

    MuiSelect: {
      defaultProps: {
        SelectDisplayProps: {
          style: {
            fontSize: "20px",
            color: "#aedff2",
          },
        },
        inputProps: {
          style: {
            fontSize: "20px",
            color: "#aedff2",
          },
        },
      },
      styleOverrides: {
        root: {
          "--TextField-brandBorderColor": "#aedff2",
          "--TextField-brandBorderHoverColor": "#FFD9A8",
          "--TextField-brandBorderFocusedColor": "#FFD9A8",
          "& > fieldset > legend": {
            fontSize: `calc(0.75 * ${labelFontSize})`,
          },
          "& label.Mui-focused": {
            color: "#FFD9A8",
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        style: {
          fontSize: "20px",
          color: "#FFD9A8",
        },
      },

      styleOverrides: {
        root: {
          "--TextField-brandBorderColor": "#aedff2",
          "--TextField-brandBorderHoverColor": "#FFD9A8",
          "--TextField-brandBorderFocusedColor": "#FFD9A8",
          "& label.Mui-focused": {
            color: "var(--TextField-brandBorderFocusedColor)",
          },
          fontSize: labelFontSize,
        },
      },
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 43,
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          "& > fieldset > legend": {
            fontSize: `calc(0.75 * ${labelFontSize})`,
          },
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        inputProps: {
          style: {
            fontSize: "20px",
            color: "#aedff2", // This sets the text field font color
          },
        },
        InputLabelProps: {
          style: {
            fontSize: labelFontSize,
            color: "#FFD9A8",
          },
        },
      },
      styleOverrides: {
        root: {
          "--TextField-brandBorderColor": "#aedff2",
          "--TextField-brandBorderHoverColor": "#FFD9A8",
          "--TextField-brandBorderFocusedColor": "#FFD9A8",
          "& label.Mui-focused": {
            color: "var(--TextField-brandBorderFocusedColor)",
          },
        },
      },
    },

    MuiOutlinedInput: {
      defaultProps: {
        style: {
          fontSize: "20px",
        },
      },
      styleOverrides: {
        notchedOutline: {
          borderColor: "var(--TextField-brandBorderColor)",
        },
        root: {
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: "var(--TextField-brandBorderHoverColor)",
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: "var(--TextField-brandBorderFocusedColor)",
          },
          "& > fieldset > legend": {
            fontSize: `calc(0.75 * ${labelFontSize})`,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          "&::before, &::after": {
            borderBottom: "2px solid var(--TextField-brandBorderColor)",
          },
          "&:hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
          },
          "&.Mui-focused:after": {
            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
          },
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        root: {
          "&::before": {
            borderBottom: "2px solid var(--TextField-brandBorderColor)",
          },
          "&:hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
          },
          "&.Mui-focused:after": {
            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
          },
        },
      },
    },
  },
  typography: {
    body2: {
      fontSize: "17px",
      color: "#FFD9A8",
    },
    helptext: {
      fontSize: "16px",
      color: "#aedff2",
    },
    menulink: {
      color: "#FFFFFF",
    },
    body1: {
      color: "#aedff2",
    },
    h1: {
      color: "#FFFFFF",
      fontSize: "22px"
    },
    h2: {
      color: "#FFD9A8",
      fontSize: "22px"
    },
    h3: {
      color: "#f0cb9c",
      fontSize: "20px"
    },
    h4: {
      color: "#FFFFFF",
      fontSize: "21px"
    },
    error: {
      color: "#fc9a9a",
    },
  },
  palette: {
    background: {
      default: "#374158",
      paper: "#282B39",
    },
    primary: {
      main: "#282B39",
    },
    secondary: {
      main: "#FFD9A8",
    },
    info: {
      main: "#aedff2",
    },
  },
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
          <Helmet>
            <title>Number Chomper | Free Calculators, Generators and Utilities</title>
            <meta
              name="description"
              content="Free, Fast and Easy-To-Use Converters, Generators and Calculators. Best online tools for web design, math, app development, health, finance and more"
            />
          </Helmet>
          <CssBaseline />
          <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
