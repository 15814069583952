import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { snakeCase } from "change-case";
import { container } from "tsyringe";
import { AnalyticsService } from "../../services/analytics";

export interface AndroidIosImageResizerState {
  imageFileName: string;
  imageSelected: boolean;
  includeAndroidImages: boolean;
  includeIosImages: boolean;
  selectedFileObjectUrl: string;
  maxResolutionHeight: number;
  maxResolutionWidth: number;
  imageAspectRatio: number;
  mdpiValueCopied: boolean;
  hdpiValueCopied: boolean;
  xhdpiValueCopied: boolean;
  xxhdpiValueCopied: boolean;
  xxxhdpiValueCopied: boolean;
}

const initialState: AndroidIosImageResizerState = {
  imageFileName: "",
  imageSelected: false,
  includeAndroidImages: true,
  includeIosImages: false,
  selectedFileObjectUrl: "",
  maxResolutionHeight: 0,
  maxResolutionWidth: 0,
  imageAspectRatio: 0,
  mdpiValueCopied: false,
  hdpiValueCopied: false,
  xhdpiValueCopied: false,
  xxhdpiValueCopied: false,
  xxxhdpiValueCopied: false,
};

export const AndroidIosImageResizerSlice = createSlice({
  name: "androidIosImageResizer",
  initialState,
  reducers: {
    includeAndroidImagesOnChanged(state, action: PayloadAction<boolean>) {
      state.includeAndroidImages = action.payload;
    },
    includeIosImagesOnChanged(state, action: PayloadAction<boolean>) {
      state.includeIosImages = action.payload;
    },
    maxResolutionHeightOnChanged(state, action: PayloadAction<string>) {
      try {
        var maxHeight = Number(action.payload);
        if (Number.isNaN(maxHeight)) {
          return;
        }
        state.maxResolutionHeight = maxHeight;
        state.maxResolutionWidth = Math.ceil(
          maxHeight / state.imageAspectRatio
        );
      } catch (error) {}
    },
    maxResolutionWidthOnChanged(state, action: PayloadAction<string>) {
      try {
        var maxWidth = Number(action.payload);
        if (Number.isNaN(maxWidth)) {
          return;
        }
        state.maxResolutionWidth = maxWidth;
        state.maxResolutionHeight = Math.ceil(
          maxWidth * state.imageAspectRatio
        );
      } catch (error) {}
    },
    mdpiValueCopiedTapped: (state) => {
      state.mdpiValueCopied = true;
      state.hdpiValueCopied = false;
      state.xhdpiValueCopied = false;
      state.xxhdpiValueCopied = false;
      state.xxxhdpiValueCopied = false;
    },
    hdpiValueCopiedTapped: (state) => {
      state.mdpiValueCopied = false;
      state.hdpiValueCopied = true;
      state.xhdpiValueCopied = false;
      state.xxhdpiValueCopied = false;
      state.xxxhdpiValueCopied = false;
    },
    xhdpiValueCopiedTapped: (state) => {
      state.mdpiValueCopied = false;
      state.hdpiValueCopied = false;
      state.xhdpiValueCopied = true;
      state.xxhdpiValueCopied = false;
      state.xxxhdpiValueCopied = false;
    },
    xxhdpiValueCopiedTapped: (state) => {
      state.mdpiValueCopied = false;
      state.hdpiValueCopied = false;
      state.xhdpiValueCopied = false;
      state.xxhdpiValueCopied = true;
      state.xxxhdpiValueCopied = false;
    },
    xxxhdpiValueCopiedTapped: (state) => {
      state.mdpiValueCopied = false;
      state.hdpiValueCopied = false;
      state.xhdpiValueCopied = false;
      state.xxhdpiValueCopied = false;
      state.xxxhdpiValueCopied = true;
    },
    downloadClicked(state) {
      state.mdpiValueCopied = false;
      state.hdpiValueCopied = false;
      state.xhdpiValueCopied = false;
      state.xxhdpiValueCopied = false;
      state.xxxhdpiValueCopied = false;
      if (state.selectedFileObjectUrl === "") {
        return;
      }
      resizeImageAsync(
        state.selectedFileObjectUrl,
        state.imageFileName,
        state.includeAndroidImages,
        state.includeIosImages,
        state.maxResolutionHeight,
        state.maxResolutionWidth
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(selectFileAsync.fulfilled, (state, action) => {
      if (action.payload === undefined) {
        return;
      }

      if (state.selectedFileObjectUrl != "") {
        URL.revokeObjectURL(state.selectedFileObjectUrl);
      }

      state.imageFileName = action.payload.name;
      state.imageSelected = true;
      state.selectedFileObjectUrl = action.payload.objectUrl;
      state.maxResolutionHeight = action.payload.maxHeight;
      state.maxResolutionWidth = action.payload.maxWidth;
      state.imageAspectRatio =
        action.payload.maxHeight / action.payload.maxWidth;
    });
  },
});

export const selectFileAsync = createAsyncThunk(
  "androidIosImageResizer/selectFileAsync",
  async (file: File | undefined) => {
    if (file === undefined) return;

    const { width, height } = await getImageDimensions(file);

    var objectUrl = URL.createObjectURL(file);
    return {
      name: file.name,
      objectUrl: objectUrl,
      maxHeight: height,
      maxWidth: width,
    };
  }
);

async function resizeImageAsync(
  objectUrl: string,
  fileName: string,
  includeAndroid: boolean,
  includeIos: boolean,
  maxResolutionHeight: number,
  maxResolutionWidth: number
) {
    const analyticsService = container.resolve(AnalyticsService);
    analyticsService.LogEvent({
      category: "Button Click",
      action: "Convert Clicked",
      label: "Android iOS Image Resizer",
    });
  await import("react-image-file-resizer").then(async (FileResizerModule) => {
    const FileResizer = FileResizerModule.default;
    await fetch(objectUrl)
      .then((response) => response.blob())
      .then(async (blob) => {
        const newFile = new File([blob], fileName, {
          type: blob.type,
          lastModified: new Date().getTime(),
        });

        const { width, height } = await getImageDimensions(newFile);
        var maxResolutionChanged = false;
        if (height != maxResolutionHeight || width != maxResolutionWidth) {
          maxResolutionChanged = true;
        }

        const resizePng = (
          file: File,
          imageWidth: number,
          imageHeight: number
        ) =>
          new Promise((resolve) => {
            FileResizer.imageFileResizer(
              file,
              imageWidth,
              imageHeight,
              "PNG",
              0.9,
              0,
              (uri) => {
                resolve(uri);
              },
              "file"
            );
          });

        var newFileName = `${snakeCase(newFile.name.split(".")[0])}`;
        const xxxhdpi = !maxResolutionChanged
          ? newFile
          : ((await resizePng(
              newFile,
              Math.ceil(maxResolutionWidth),
              Math.ceil(maxResolutionHeight)
            )) as File);

        const xxhdpi = (await resizePng(
          newFile,
          Math.ceil((maxResolutionWidth / 4) * 3),
          Math.ceil((maxResolutionHeight / 4) * 3)
        )) as File;
        const xhdpi = (await resizePng(
          newFile,
          Math.ceil((maxResolutionWidth / 4) * 2),
          Math.ceil((maxResolutionHeight / 4) * 2)
        )) as File;
        const hdpi = (await resizePng(
          newFile,
          Math.ceil((maxResolutionWidth / 4) * 1.5),
          Math.ceil((maxResolutionHeight / 4) * 1.5)
        )) as File;
        const mdpi = (await resizePng(
          newFile,
          Math.ceil(maxResolutionWidth / 4),
          Math.ceil(maxResolutionHeight / 4)
        )) as File;
        const ldpi = (await resizePng(
          newFile,
          Math.ceil((maxResolutionWidth / 4) * 0.75),
          Math.ceil((maxResolutionHeight / 4) * 0.75)
        )) as File;

        const x3 = !maxResolutionChanged
          ? newFile
          : ((await resizePng(
              newFile,
              Math.ceil(maxResolutionWidth),
              Math.ceil(maxResolutionHeight)
            )) as File);
        const x2 = (await resizePng(
          newFile,
          Math.ceil((maxResolutionWidth / 3) * 2),
          Math.ceil((maxResolutionHeight / 3) * 2)
        )) as File;
        const x1 = (await resizePng(
          newFile,
          Math.ceil(maxResolutionWidth / 3),
          Math.ceil(maxResolutionHeight / 3)
        )) as File;

        await import("jszip").then(async (JSZipModule) => {
          const JSZip = JSZipModule.default;
          const zip = new JSZip();

          if (includeAndroid) {
            zip.file(`Android/drawable-xxxhdpi/${newFileName}.png`, xxxhdpi);
            zip.file(`Android/drawable-xxhdpi/${newFileName}.png`, xxhdpi);
            zip.file(`Android/drawable-xhdpi/${newFileName}.png`, xhdpi);
            zip.file(`Android/drawable-hdpi/${newFileName}.png`, hdpi);
            zip.file(`Android/drawable-mdpi/${newFileName}.png`, mdpi);
            zip.file(`Android/drawable-ldpi/${newFileName}.png`, ldpi);
          }

          if (includeIos) {
            zip.file(`iOS/${newFileName}@1x.png`, x1);
            zip.file(`iOS/${newFileName}@2x.png`, x2);
            zip.file(`iOS/${newFileName}@3x.png`, x3);
          }

          var zipBlob = await zip.generateAsync({ type: "blob" });

          // Create a download link
          const url = window.URL.createObjectURL(zipBlob);
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.href = url;
          a.download = `${newFile.name.split(".")[0]}.zip`;
          a.click();
          window.URL.revokeObjectURL(url);
        });
      });
  });
}

export const {
  includeAndroidImagesOnChanged,
  includeIosImagesOnChanged,
  downloadClicked,
  maxResolutionHeightOnChanged,
  maxResolutionWidthOnChanged,
  mdpiValueCopiedTapped,
  hdpiValueCopiedTapped,
  xhdpiValueCopiedTapped,
  xxhdpiValueCopiedTapped,
  xxxhdpiValueCopiedTapped
} = AndroidIosImageResizerSlice.actions;

export const selectState = (state: RootState) => state.androidIosImageResizer;

export default AndroidIosImageResizerSlice.reducer;

async function getImageDimensions(file: File) {
  let img = new Image();
  img.src = URL.createObjectURL(file);
  await img.decode();
  let width = img.width;
  let height = img.height;
  return {
    width,
    height,
  };
}
