import React from "react";
import { SideMenu } from "../../features/side_menu/SideMenu";
import { PageContainer } from "../page_container/PageContainer";

export class SideMenuPageContainer extends React.Component<{
  pageTitle: string;
  pageDescription: string;
  pageRelativeUrl: string;
  children?: React.ReactNode;
}> {
  render() {
    return (
      <SideMenu>
        <PageContainer
          pageTitle={this.props.pageTitle}
          pageDescription={this.props.pageDescription}
          pageRelativeUrl={this.props.pageRelativeUrl}
        >
          {this.props.children}
        </PageContainer>
      </SideMenu>
    );
  }
}
