import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import styles from "./ResultCard.module.css";
import { Box, Container, Stack } from "@mui/system";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

export class ResultCard extends React.Component<{
  value: string;
  symbol: string;
  description?: string;
  resultCopied: boolean;
  symbolAtStart?: boolean;
  smallResult?: boolean;
  onCopy: (text: string, result: boolean) => void;
}> {
  render() {
    var cardValue = "";

    if (this.props.symbol == null || this.props.symbol == "") {
      cardValue = this.props.value;
    } else if (this.props.symbolAtStart == true) {
      cardValue = `${this.props.symbol} ${this.props.value}`;
    } else {
      cardValue = `${this.props.value} ${this.props.symbol}`;
    }

    return (
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Box
          sx={{
            width: { xs: 280, sm: 320, md: 400 },
            mb: 1,
          }}
        >
          <CopyToClipboard text={cardValue} onCopy={this.props.onCopy}>
            <div className={this.props.smallResult ? styles.smallcard : styles.card}>
              <div className={this.props.smallResult ? styles.smallcardcontent : styles.cardcontent}>
                <div className={styles.cardcontentsecondary}>
                  <label>{this.props.description}</label>
                  <div
                    className={`${
                      this.props.resultCopied ? styles.copyiconhidden : ""
                    }`}
                  >
                    <ContentCopyIcon sx={{ height: "30px", width: "30px" }} />
                  </div>
                  <div
                    className={`${styles.cardcopied} ${
                      this.props.resultCopied ? "" : styles.copyiconhidden
                    }`}
                  >
                    <TaskAltIcon sx={{ height: "30px", width: "30px" }} />
                  </div>
                </div>
                <div>
                  <label>{cardValue}</label>
                </div>
              </div>
            </div>
          </CopyToClipboard>
        </Box>
      </Stack>
    );
  }
}
