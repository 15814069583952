import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { container } from "tsyringe";
import { RootState } from "../../app/store";
import { AnalyticsService } from "../../services/analytics";
import { round } from "../../utilities/maths";

export enum DistanceUnitType {
  milimeter,
  centimeter,
  meter,
  inch,
  feet,
}

export interface DistanceUnitConverterState {
  inputValue: number;
  inputUnit: DistanceUnitType;
  outputUnit: DistanceUnitType;
  resultUnit: DistanceUnitType;
  outputValue: number;
  resultCopied: boolean;
  resultSymbol: string;
  inputSymbol: string;
}

const initialState: DistanceUnitConverterState = {
  inputValue: 100,
  inputUnit: DistanceUnitType.centimeter,
  outputUnit: DistanceUnitType.inch,
  resultUnit: DistanceUnitType.inch,
  outputValue: 39.3701,
  resultCopied: false,
  resultSymbol: "''",
  inputSymbol: "cm",
};

export const DistanceUnitConverterSlice = createSlice({
  name: "distanceUnitConverter",
  initialState,
  reducers: {
    inputValueOnChanged: (state, action: PayloadAction<number>) => {
      state.inputValue = action.payload;
    },
    inputTypeOnChanged: (state, action: PayloadAction<DistanceUnitType>) => {
      state.inputUnit = action.payload;
      state.inputSymbol = getSymbol(action.payload);
    },
    outputTypeOnChanged: (state, action: PayloadAction<DistanceUnitType>) => {
      state.outputUnit = action.payload;
    },
    calculateTapped: (state) => {
      const analyticsService = container.resolve(AnalyticsService);
      analyticsService.LogEvent({
        category: "Button Click",
        action: "Convert Clicked",
        label: "Distance Unit Converter",
      });
      switch (state.inputUnit) {
        case DistanceUnitType.milimeter:
          switch (state.outputUnit) {
            case DistanceUnitType.centimeter:
              state.outputValue = round(state.inputValue / 10, 4);
              break;
            case DistanceUnitType.meter:
              state.outputValue = round(state.inputValue / 1000, 4);
              break;
            case DistanceUnitType.inch:
              state.outputValue = round(state.inputValue / 25.4, 4);
              break;
            case DistanceUnitType.feet:
              state.outputValue = round(state.inputValue / 304.8, 4);
              break;
            case DistanceUnitType.milimeter:
            default:
              state.outputValue = round(state.inputValue, 4);
              break;
          }
          break;
        case DistanceUnitType.centimeter:
          switch (state.outputUnit) {
            case DistanceUnitType.milimeter:
              state.outputValue = round(state.inputValue * 10, 4);
              break;
            case DistanceUnitType.meter:
              state.outputValue = round(state.inputValue / 100, 4);
              break;
            case DistanceUnitType.inch:
              state.outputValue = round(state.inputValue / 2.54, 4);
              break;
            case DistanceUnitType.feet:
              state.outputValue = round(state.inputValue / 30.48, 4);
              break;
            case DistanceUnitType.centimeter:
            default:
              state.outputValue = round(state.inputValue, 4);
              break;
          }
          break;
        case DistanceUnitType.meter:
          switch (state.outputUnit) {
            case DistanceUnitType.milimeter:
              state.outputValue = round(state.inputValue * 1000, 4);
              break;
            case DistanceUnitType.centimeter:
              state.outputValue = round(state.inputValue * 100, 4);
              break;
            case DistanceUnitType.inch:
              state.outputValue = round(state.inputValue * 39.37, 4);
              break;
            case DistanceUnitType.feet:
              state.outputValue = round(state.inputValue * 3.281, 4);
              break;
            case DistanceUnitType.meter:
            default:
              state.outputValue = round(state.inputValue, 4);
              break;
          }
          break;
        case DistanceUnitType.inch:
          switch (state.outputUnit) {
            case DistanceUnitType.milimeter:
              state.outputValue = round(state.inputValue * 25.4, 4);
              break;
            case DistanceUnitType.centimeter:
              state.outputValue = round(state.inputValue * 2.54, 4);
              break;
            case DistanceUnitType.meter:
              state.outputValue = round(state.inputValue / 39.37, 4);
              break;
            case DistanceUnitType.feet:
              state.outputValue = round(state.inputValue / 12, 4);
              break;
            case DistanceUnitType.inch:
            default:
              state.outputValue = round(state.inputValue, 4);
              break;
          }
          break;
        case DistanceUnitType.feet:
          switch (state.outputUnit) {
            case DistanceUnitType.milimeter:
              state.outputValue = round(state.inputValue * 304.8, 4);
              break;
            case DistanceUnitType.centimeter:
              state.outputValue = round(state.inputValue * 30.48, 4);
              break;
            case DistanceUnitType.meter:
              state.outputValue = round(state.inputValue / 3.281, 4);
              break;
            case DistanceUnitType.inch:
              state.outputValue = round(state.inputValue * 12, 4);
              break;
            case DistanceUnitType.feet:
            default:
              state.outputValue = round(state.inputValue, 4);
              break;
          }
          break;

        default:
          break;
      }

      state.resultSymbol = getSymbol(state.outputUnit);
      state.resultCopied = false;
      state.resultUnit = state.outputUnit;
    },
    resultCopyTapped: (state) => {
      state.resultCopied = true;
    },
  },
});

function getSymbol(distanceUnitType: DistanceUnitType) {
  switch (distanceUnitType) {
    case DistanceUnitType.milimeter:
      return "mm";
    case DistanceUnitType.centimeter:
      return "cm";
    case DistanceUnitType.meter:
      return "m";
    case DistanceUnitType.inch:
      return "''";
    case DistanceUnitType.feet:
      return "ft";
    default:
      return "";
  }
}

export const {
  calculateTapped,
  inputTypeOnChanged,
  outputTypeOnChanged,
  inputValueOnChanged,
  resultCopyTapped,
} = DistanceUnitConverterSlice.actions;
export const selectState = (state: RootState) => state.distanceUnitConverter;

export default DistanceUnitConverterSlice.reducer;
