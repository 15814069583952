import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import baseConverterReducer from '../features/base_converter/BaseConverterSlice';
import bmiCalculatorReducer from '../features/bmi_calculator/BmiCalculatorSlice';
import distanceUnitConverterReducer from '../features/distance_unit_converter/DistanceUnitConverterSlice';
import faviconGeneratorReducer from '../features/favicon_generator/FaviconGeneratorSlice';
import temperatureConverterReducer from '../features/temperature_converter/TemperatureConverterSlice';
import weightUnitConverterReducer from '../features/weight_unit_converter/WeightUnitConverterSlice';
import compoundInterestCalculatorReducer from '../features/compound_interest_calculator/CompoundInterestCalculatorSlice';
import creditCardRepaymentCalculatorReducer from '../features/credit_card_repayment_calculator/CreditCardRepaymentCalculatorSlice';
import homeReducer from '../features/home/HomeSlice';
import sideMenuReducer from '../features/side_menu/SideMenuSlice';
import androidIosImageResizerReducer from '../features/android_ios_image_resizer/AndroidIosImageResizerSlice';

export const store = configureStore({
  reducer: {
    baseConverter: baseConverterReducer,
    bmiCalculator: bmiCalculatorReducer,
    distanceUnitConverter: distanceUnitConverterReducer,
    weightUnitConverter: weightUnitConverterReducer,
    faviconGenerator: faviconGeneratorReducer,
    temperatureConverter: temperatureConverterReducer,
    compoundInterestCalculator : compoundInterestCalculatorReducer,
    creditCardRepaymentCalculator : creditCardRepaymentCalculatorReducer,
    home : homeReducer,
    sideMenu: sideMenuReducer,
    androidIosImageResizer: androidIosImageResizerReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
