import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import * as changeCase from "change-case";

import {
  DistanceUnitType,
  calculateTapped,
  inputTypeOnChanged,
  inputValueOnChanged,
  outputTypeOnChanged,
  resultCopyTapped,
  selectState,
} from "./DistanceUnitConverterSlice";
import {
  Box,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { ResultCard } from "../../components/result_card/ResultCard";
import { CustomButton } from "../../components/custom_button/CustomButton";
import { EqualColumnTable } from "../../components/equal_column_table/EqualColumnTable";
import { SideMenuPageContainer } from "../../components/side_menu_page_container/SideMenuPageContainer";
import Grid2 from "@mui/material/Unstable_Grid2";
import { HomeCard } from "../../components/home_card/HomeCard";
import WeightUnitConverterImage from "../../assets/WeightUnitConverter.webp";
import TemperatureConverterImage from "../../assets/TemperatureConverter.webp";
import { CustomSelect } from "../../components/custom_select/CustomSelect";

export function DistanceUnitConverter() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectState);

  return (
    <SideMenuPageContainer
      pageTitle="Distance Unit Converter"
      pageRelativeUrl="DistanceUnitConverter"
      pageDescription="Convert between Millimeter, Centimeter, Meter, Inch and Feet"
    >
      <Grid2
        container
        spacing={2}
        sx={{ mt: 2 }}
        columns={{ xs: 6, sm: 6, md: 12 }}
      >
        <Grid2 xs={12}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Convert between Millimeter, Centimeter, Meter, Inch and Feet
          </Typography>
        </Grid2>
        <Grid2 xs={6}>
          <CustomSelect
            label="From"
            value={state.inputUnit}
            onChange={(e) =>
              dispatch(
                inputTypeOnChanged(Number(e.target.value) as DistanceUnitType)
              )
            }
          >
            <MenuItem value={DistanceUnitType.milimeter}>Milimeter</MenuItem>
            <MenuItem value={DistanceUnitType.centimeter}>Centimeter</MenuItem>
            <MenuItem value={DistanceUnitType.meter}>Meter</MenuItem>
            <MenuItem value={DistanceUnitType.inch}>Inch</MenuItem>
            <MenuItem value={DistanceUnitType.feet}>Feet</MenuItem>
          </CustomSelect>
        </Grid2>
        <Grid2 xs={6}>
          <CustomSelect
            label="To"
            value={state.outputUnit}
            onChange={(e) =>
              dispatch(
                outputTypeOnChanged(Number(e.target.value) as DistanceUnitType)
              )
            }
          >
            <MenuItem value={DistanceUnitType.milimeter}>Milimeter</MenuItem>
            <MenuItem value={DistanceUnitType.centimeter}>Centimeter</MenuItem>
            <MenuItem value={DistanceUnitType.meter}>Meter</MenuItem>
            <MenuItem value={DistanceUnitType.inch}>Inch</MenuItem>
            <MenuItem value={DistanceUnitType.feet}>Feet</MenuItem>
          </CustomSelect>
        </Grid2>
        <Grid2 xs={6}>
          <TextField
            fullWidth
            label="Value"
            variant="outlined"
            color="info"
            value={state.inputValue || ""}
            onChange={(e) =>
              dispatch(inputValueOnChanged(Number(e.target.value)))
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography>{state.inputSymbol}</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid2>
        <Grid2 xs={12} sx={{ mt: 1, mb: 3 }}>
          <CustomButton
            label="Convert"
            onClick={() => {
              dispatch(calculateTapped());
            }}
          />
        </Grid2>
        <Grid2 xs={12}>
          <ResultCard
            value={`${state.outputValue}`}
            symbol={`${state.resultSymbol}`}
            description={changeCase.pascalCase(
              DistanceUnitType[state.resultUnit]
            )}
            onCopy={() => dispatch(resultCopyTapped())}
            resultCopied={state.resultCopied}
          />
        </Grid2>
        <Grid2 xs={12}>
          <Typography variant="h3" sx={{ mt: 3, mb: 1 }}>
            Related
          </Typography>
        </Grid2>
        <Grid2 xs={6}>
          <HomeCard
            title="Weight Unit Converter"
            description="Convert between Pound, Kilogram, Gram, Ounce, Milligram and other weight measurements"
            tooltip="Convert Weight Measurements Online - Lb, g, mg, kg, dg, hg, t and more"
            link="/WeightUnitConverter"
            smallCard={true}
            image={WeightUnitConverterImage}
          />
        </Grid2>
        <Grid2 xs={6}>
          <HomeCard
            title="Temperature Converter"
            description="Convert between Celsius, Kelvin and Fahrenheit"
            tooltip="Instantly convert between °C, °F and K"
            link="/TemperatureConverter"
            smallCard={true}
            image={TemperatureConverterImage}
          />
        </Grid2>
      </Grid2>
    </SideMenuPageContainer>
  );
}
