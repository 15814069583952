import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { container } from "tsyringe";
import { RootState } from '../../app/store';
import { AnalyticsService } from "../../services/analytics";
import { round } from "../../utilities/maths";

export enum WeightUnitType {
  centigram,
  decigram,
  dekagram,
  dram,
  grain,
  gram,
  hectogram,
  hundredWeightLongUk,
  hunderdWeightShortUs,
  kilogram,
  lbLbs,
  longTon,
  megagram,
  metricTon,
  microgram,
  milligram,
  ounce,
  pound,
  shortTon,
  tonLongUk,
  tonMetric,
  tonShortUs,
  tonne,
}

export interface WeightUnitConverterState {
  inputValue: number;
  inputUnit: WeightUnitType;
  outputUnit: WeightUnitType;
  resultUnit: WeightUnitType;
  outputValue: number;
  resultCopied: boolean;
  resultSymbol: string;
  inputSymbol: string;
}

const initialState: WeightUnitConverterState = {
  inputValue: 1,
  inputUnit: WeightUnitType.pound,
  outputUnit: WeightUnitType.kilogram,
  resultUnit: WeightUnitType.kilogram,
  outputValue: 0.45359237,
  resultCopied: false,
  resultSymbol: "kg",
  inputSymbol: "lb",
};

export const WeightUnitConverterSlice = createSlice({
  name: "weightUnitConverter",
  initialState,
  reducers: {
    inputValueOnChanged: (state, action: PayloadAction<number>) => {
      state.inputValue = action.payload;
    },
    inputTypeOnChanged: (state, action: PayloadAction<WeightUnitType>) => {
      state.inputUnit = action.payload;
      state.inputSymbol = getSymbol(action.payload)
    },
    outputTypeOnChanged: (state, action: PayloadAction<WeightUnitType>) => {
      state.outputUnit = action.payload;
    },
    calculateTapped: (state) => {
      const analyticsService = container.resolve(AnalyticsService);
      analyticsService.LogEvent({    
        category: 'Button Click',
        action: 'Convert Clicked',
        label: 'Weight Unit Converter',
      });
      switch (state.inputUnit) {
        case WeightUnitType.centigram:
          state.outputValue = centigramConversions(
            state.inputValue,
            state.outputUnit
          );
          break;
        case WeightUnitType.decigram:
          state.outputValue = decigramConversions(
            state.inputValue,
            state.outputUnit
          );
          break;
        case WeightUnitType.dekagram:
          state.outputValue = dekagramConversions(
            state.inputValue,
            state.outputUnit
          );
          break;
        case WeightUnitType.dram:
          state.outputValue = dramConversions(
            state.inputValue,
            state.outputUnit
          );
          break;
        case WeightUnitType.grain:
          state.outputValue = grainConversions(
            state.inputValue,
            state.outputUnit
          );
          break;
        case WeightUnitType.gram:
          state.outputValue = gramConversions(
            state.inputValue,
            state.outputUnit
          );
          break;
        case WeightUnitType.hectogram:
          state.outputValue = hectogramConversions(
            state.inputValue,
            state.outputUnit
          );
          break;
        case WeightUnitType.hundredWeightLongUk:
          state.outputValue = hundredweightLongUkConversions(
            state.inputValue,
            state.outputUnit
          );
          break;
        case WeightUnitType.hunderdWeightShortUs:
          state.outputValue = hundredweightShortUsConversions(
            state.inputValue,
            state.outputUnit
          );
          break;
        case WeightUnitType.kilogram:
          state.outputValue = kilogramConversions(
            state.inputValue,
            state.outputUnit
          );
          break;
        case WeightUnitType.lbLbs:
          state.outputValue = lbLbsConversions(
            state.inputValue,
            state.outputUnit
          );
          break;
        case WeightUnitType.longTon:
          state.outputValue = longTonConversions(
            state.inputValue,
            state.outputUnit
          );
          break;
        case WeightUnitType.megagram:
          state.outputValue = megagramConversions(
            state.inputValue,
            state.outputUnit
          );
          break;
        case WeightUnitType.metricTon:
          state.outputValue = metricTonConversions(
            state.inputValue,
            state.outputUnit
          );
          break;
        case WeightUnitType.microgram:
          state.outputValue = microGramConversions(
            state.inputValue,
            state.outputUnit
          );
          break;
        case WeightUnitType.milligram:
          state.outputValue = milligramConversions(
            state.inputValue,
            state.outputUnit
          );
          break;
        case WeightUnitType.ounce:
          state.outputValue = ounceConversions(
            state.inputValue,
            state.outputUnit
          );
          break;
        case WeightUnitType.pound:
          state.outputValue = poundConversions(
            state.inputValue,
            state.outputUnit
          );
          break;
        case WeightUnitType.shortTon:
          state.outputValue = shortTonConversions(
            state.inputValue,
            state.outputUnit
          );
          break;
        case WeightUnitType.tonLongUk:
          state.outputValue = tonLongUkConversions(
            state.inputValue,
            state.outputUnit
          );
          break;
        case WeightUnitType.tonMetric:
          state.outputValue = tonMetricConversions(
            state.inputValue,
            state.outputUnit
          );
          break;
        case WeightUnitType.tonShortUs:
          state.outputValue = tonShortUsConversions(
            state.inputValue,
            state.outputUnit
          );
          break;
        case WeightUnitType.tonne:
          state.outputValue = tonneConversions(
            state.inputValue,
            state.outputUnit
          );
          break;
      }
      state.resultCopied = false;
      state.resultSymbol = getSymbol(state.outputUnit);
      state.resultUnit = state.outputUnit;

    },
    resultCopyTapped: (state) => {
      state.resultCopied = true;
    },
  },
});

export const {
  calculateTapped,
  inputTypeOnChanged,
  outputTypeOnChanged,
  inputValueOnChanged,
  resultCopyTapped,
} = WeightUnitConverterSlice.actions;
export const selectState = (state: RootState) => state.weightUnitConverter;

export default WeightUnitConverterSlice.reducer;

function centigramConversions(inputValue: number, outputType: WeightUnitType) {
  var factor = 0.0;
  switch (outputType) {
    case WeightUnitType.centigram:
      factor = 1.0;
      break;
    case WeightUnitType.decigram:
      factor = 0.1;
      break;
    case WeightUnitType.dekagram:
      factor = 0.001;
      break;
    case WeightUnitType.dram:
      factor = 0.005643833912;
      break;
    case WeightUnitType.grain:
      factor = 0.15432358353;
      break;
    case WeightUnitType.gram:
      factor = 0.01;
      break;
    case WeightUnitType.hectogram:
      factor = 0.0001;
      break;
    case WeightUnitType.hundredWeightLongUk:
      factor = 1.9684130552e-7;
      break;
    case WeightUnitType.hunderdWeightShortUs:
      factor = 2.2046226218e-7;
      break;
    case WeightUnitType.kilogram:
      factor = 0.00001;
      break;
    case WeightUnitType.lbLbs:
      factor = 0.000022046226218;
      break;
    case WeightUnitType.longTon:
      factor = 9.8420652761e-9;
      break;
    case WeightUnitType.megagram:
      factor = 1.0e-8;
      break;
    case WeightUnitType.metricTon:
      factor = 1.0e-8;
      break;
    case WeightUnitType.microgram:
      factor = 10000;
      break;
    case WeightUnitType.milligram:
      factor = 10;
      break;
    case WeightUnitType.ounce:
      factor = 0.0003527396195;
      break;
    case WeightUnitType.pound:
      factor = 0.000022046226218;
      break;
    case WeightUnitType.shortTon:
      factor = 1.1023113109e-8;
      break;
    case WeightUnitType.tonLongUk:
      factor = 9.8420652761e-9;
      break;
    case WeightUnitType.tonMetric:
      factor = 1.0e-8;
      break;
    case WeightUnitType.tonShortUs:
      factor = 1.1023113109e-8;
      break;
    case WeightUnitType.tonne:
      factor = 1.0e-8;
      break;
  }
  return round(inputValue * factor, 50);
}

function decigramConversions(inputValue: number, outputType: WeightUnitType) {
  var factor = 0.0;
  switch (outputType) {
    case WeightUnitType.centigram:
      factor = 10;
      break;
    case WeightUnitType.decigram:
      factor = 1;
      break;
    case WeightUnitType.dekagram:
      factor = 0.01;
      break;
    case WeightUnitType.dram:
      factor = 0.05643833912;
      break;
    case WeightUnitType.grain:
      factor = 1.5432358353;
      break;
    case WeightUnitType.gram:
      factor = 0.1;
      break;
    case WeightUnitType.hectogram:
      factor = 0.001;
      break;
    case WeightUnitType.hundredWeightLongUk:
      factor = 0.0000019684130552;
      break;
    case WeightUnitType.hunderdWeightShortUs:
      factor = 0.0000022046226218;
      break;
    case WeightUnitType.kilogram:
      factor = 0.0001;
      break;
    case WeightUnitType.lbLbs:
      factor = 0.00022046226218;
      break;
    case WeightUnitType.longTon:
      factor = 9.8420652761e-8;
      break;
    case WeightUnitType.megagram:
      factor = 1.0e-7;
      break;
    case WeightUnitType.metricTon:
      factor = 1.0e-7;
      break;
    case WeightUnitType.microgram:
      factor = 100000;
      break;
    case WeightUnitType.milligram:
      factor = 100;
      break;
    case WeightUnitType.ounce:
      factor = 0.003527396195;
      break;
    case WeightUnitType.pound:
      factor = 0.00022046226218;
      break;
    case WeightUnitType.shortTon:
      factor = 1.1023113109e-7;
      break;
    case WeightUnitType.tonLongUk:
      factor = 9.8420652761e-8;
      break;
    case WeightUnitType.tonMetric:
      factor = 1.0e-7;
      break;
    case WeightUnitType.tonShortUs:
      factor = 1.1023113109e-7;
      break;
    case WeightUnitType.tonne:
      factor = 1.0e-7;
      break;
  }
  return round(inputValue * factor, 50);
}

function dekagramConversions(inputValue: number, outputType: WeightUnitType) {
  var factor = 0.0;
  switch (outputType) {
    case WeightUnitType.centigram:
      factor = 1000;
      break;
    case WeightUnitType.decigram:
      factor = 100;
      break;
    case WeightUnitType.dekagram:
      factor = 1;
      break;
    case WeightUnitType.dram:
      factor = 5.643833912;
      break;
    case WeightUnitType.grain:
      factor = 154.32358353;
      break;
    case WeightUnitType.gram:
      factor = 10;
      break;
    case WeightUnitType.hectogram:
      factor = 0.1;
      break;
    case WeightUnitType.hundredWeightLongUk:
      factor = 0.0000019684130552;
      break;
    case WeightUnitType.hunderdWeightShortUs:
      factor = 0.00022046226218;
      break;
    case WeightUnitType.kilogram:
      factor = 0.01;
      break;
    case WeightUnitType.lbLbs:
      factor = 0.022046226218;
      break;
    case WeightUnitType.longTon:
      factor = 0.0000098420652761;
      break;
    case WeightUnitType.megagram:
      factor = 0.00001;
      break;
    case WeightUnitType.metricTon:
      factor = 0.00001;
      break;
    case WeightUnitType.microgram:
      factor = 10000000;
      break;
    case WeightUnitType.milligram:
      factor = 10000;
      break;
    case WeightUnitType.ounce:
      factor = 0.3527396195;
      break;
    case WeightUnitType.pound:
      factor = 0.022046226218;
      break;
    case WeightUnitType.shortTon:
      factor = 0.000011023113109;
      break;
    case WeightUnitType.tonLongUk:
      factor = 0.0000098420652761;
      break;
    case WeightUnitType.tonMetric:
      factor = 0.00001;
      break;
    case WeightUnitType.tonShortUs:
      factor = 0.000011023113109;
      break;
    case WeightUnitType.tonne:
      factor = 0.00001;
      break;
  }
  return round(inputValue * factor, 50);
}

function dramConversions(inputValue: number, outputType: WeightUnitType) {
  var factor = 0.0;
  switch (outputType) {
    case WeightUnitType.centigram:
      factor = 177.18451953;
      break;
    case WeightUnitType.decigram:
      factor = 17.718451953;
      break;
    case WeightUnitType.dekagram:
      factor = 0.17718451953;
      break;
    case WeightUnitType.dram:
      factor = 1;
      break;
    case WeightUnitType.grain:
      factor = 27.34375;
      break;
    case WeightUnitType.gram:
      factor = 1.7718451953;
      break;
    case WeightUnitType.hectogram:
      factor = 0.017718451953;
      break;
    case WeightUnitType.hundredWeightLongUk:
      factor = 0.000034877232143;
      break;
    case WeightUnitType.hunderdWeightShortUs:
      factor = 0.0000390625;
      break;
    case WeightUnitType.kilogram:
      factor = 0.0017718451953;
      break;
    case WeightUnitType.lbLbs:
      factor = 0.00390625;
      break;
    case WeightUnitType.longTon:
      factor = 0.0000017438616071;
      break;
    case WeightUnitType.megagram:
      factor = 0.0000017718451953;
      break;
    case WeightUnitType.metricTon:
      factor = 0.0000017718451953;
      break;
    case WeightUnitType.microgram:
      factor = 1771845.1953;
      break;
    case WeightUnitType.milligram:
      factor = 1771.8451953;
      break;
    case WeightUnitType.ounce:
      factor = 0.0625;
      break;
    case WeightUnitType.pound:
      factor = 0.00390625;
      break;
    case WeightUnitType.shortTon:
      factor = 0.000001953125;
      break;
    case WeightUnitType.tonLongUk:
      factor = 0.0000017438616071;
      break;
    case WeightUnitType.tonMetric:
      factor = 0.0000017718451953;
      break;
    case WeightUnitType.tonShortUs:
      factor = 0.000001953125;
      break;
    case WeightUnitType.tonne:
      factor = 0.0000017718451953;
      break;
  }
  return round(inputValue * factor, 50);
}

function grainConversions(inputValue: number, outputType: WeightUnitType) {
  var factor = 0.0;
  switch (outputType) {
    case WeightUnitType.centigram:
      factor = 6.479891;
      break;
    case WeightUnitType.decigram:
      factor = 0.6479891;
      break;
    case WeightUnitType.dekagram:
      factor = 0.006479891;
      break;
    case WeightUnitType.dram:
      factor = 0.036571428572;
      break;
    case WeightUnitType.grain:
      factor = 1;
      break;
    case WeightUnitType.gram:
      factor = 0.06479891;
      break;
    case WeightUnitType.hectogram:
      factor = 0.0006479891;
      break;
    case WeightUnitType.hundredWeightLongUk:
      factor = 0.000001275102041;
      break;
    case WeightUnitType.hunderdWeightShortUs:
      factor = 0.0000014285714286;
      break;
    case WeightUnitType.kilogram:
      factor = 0.00006479891;
      break;
    case WeightUnitType.lbLbs:
      factor = 0.00014285714286;
      break;
    case WeightUnitType.longTon:
      factor = 6.3775510204e-8;
      break;
    case WeightUnitType.megagram:
      factor = 6.479891e-8;
      break;
    case WeightUnitType.metricTon:
      factor = 6.479891e-8;
      break;
    case WeightUnitType.microgram:
      factor = 64798.91;
      break;
    case WeightUnitType.milligram:
      factor = 64.79891;
      break;
    case WeightUnitType.ounce:
      factor = 0.0022857142857;
      break;
    case WeightUnitType.pound:
      factor = 0.00014285714286;
      break;
    case WeightUnitType.shortTon:
      factor = 7.1428571429e-8;
      break;
    case WeightUnitType.tonLongUk:
      factor = 6.3775510204e-8;
      break;
    case WeightUnitType.tonMetric:
      factor = 6.479891e-8;
      break;
    case WeightUnitType.tonShortUs:
      factor = 7.1428571429e-8;
      break;
    case WeightUnitType.tonne:
      factor = 6.479891e-8;
      break;
  }
  return round(inputValue * factor, 50);
}

function gramConversions(inputValue: number, outputType: WeightUnitType) {
  var factor = 0.0;
  switch (outputType) {
    case WeightUnitType.centigram:
      factor = 100;
      break;
    case WeightUnitType.decigram:
      factor = 10;
      break;
    case WeightUnitType.dekagram:
      factor = 0.1;
      break;
    case WeightUnitType.dram:
      factor = 0.5643833912;
      break;
    case WeightUnitType.grain:
      factor = 15.432358353;
      break;
    case WeightUnitType.gram:
      factor = 1;
      break;
    case WeightUnitType.hectogram:
      factor = 0.01;
      break;
    case WeightUnitType.hundredWeightLongUk:
      factor = 0.000019684130552;
      break;
    case WeightUnitType.hunderdWeightShortUs:
      factor = 0.000022046226218;
      break;
    case WeightUnitType.kilogram:
      factor = 0.001;
      break;
    case WeightUnitType.lbLbs:
      factor = 0.0022046226218;
      break;
    case WeightUnitType.longTon:
      factor = 9.8420652761e-7;
      break;
    case WeightUnitType.megagram:
      factor = 0.000001;
      break;
    case WeightUnitType.metricTon:
      factor = 0.000001;
      break;
    case WeightUnitType.microgram:
      factor = 1000000;
      break;
    case WeightUnitType.milligram:
      factor = 1000;
      break;
    case WeightUnitType.ounce:
      factor = 0.03527396195;
      break;
    case WeightUnitType.pound:
      factor = 0.0022046226218;
      break;
    case WeightUnitType.shortTon:
      factor = 0.0000011023113109;
      break;
    case WeightUnitType.tonLongUk:
      factor = 9.8420652761e-7;
      break;
    case WeightUnitType.tonMetric:
      factor = 0.000001;
      break;
    case WeightUnitType.tonShortUs:
      factor = 0.0000011023113109;
      break;
    case WeightUnitType.tonne:
      factor = 0.000001;
      break;
  }
  return round(inputValue * factor, 50);
}

function hectogramConversions(inputValue: number, outputType: WeightUnitType) {
  var factor = 0.0;
  switch (outputType) {
    case WeightUnitType.centigram:
      factor = 10000;
      break;
    case WeightUnitType.decigram:
      factor = 1000;
      break;
    case WeightUnitType.dekagram:
      factor = 10;
      break;
    case WeightUnitType.dram:
      factor = 56.43833912;
      break;
    case WeightUnitType.grain:
      factor = 1543.2358353;
      break;
    case WeightUnitType.gram:
      factor = 100;
      break;
    case WeightUnitType.hectogram:
      factor = 1;
      break;
    case WeightUnitType.hundredWeightLongUk:
      factor = 0.0019684130552;
      break;
    case WeightUnitType.hunderdWeightShortUs:
      factor = 0.0022046226218;
      break;
    case WeightUnitType.kilogram:
      factor = 0.1;
      break;
    case WeightUnitType.lbLbs:
      factor = 0.22046226218;
      break;
    case WeightUnitType.longTon:
      factor = 0.000098420652761;
      break;
    case WeightUnitType.megagram:
      factor = 0.0001;
      break;
    case WeightUnitType.metricTon:
      factor = 0.0001;
      break;
    case WeightUnitType.microgram:
      factor = 100000000;
      break;
    case WeightUnitType.milligram:
      factor = 100000;
      break;
    case WeightUnitType.ounce:
      factor = 3.527396195;
      break;
    case WeightUnitType.pound:
      factor = 0.22046226218;
      break;
    case WeightUnitType.shortTon:
      factor = 0.00011023113109;
      break;
    case WeightUnitType.tonLongUk:
      factor = 0.000098420652761;
      break;
    case WeightUnitType.tonMetric:
      factor = 0.0001;
      break;
    case WeightUnitType.tonShortUs:
      factor = 0.00011023113109;
      break;
    case WeightUnitType.tonne:
      factor = 0.0001;
      break;
  }
  return round(inputValue * factor, 50);
}

function hundredweightLongUkConversions(
  inputValue: number,
  outputType: WeightUnitType
) {
  var factor = 0.0;
  switch (outputType) {
    case WeightUnitType.centigram:
      factor = 5080234.544;
      break;
    case WeightUnitType.decigram:
      factor = 508023.4544;
      break;
    case WeightUnitType.dekagram:
      factor = 5080.234544;
      break;
    case WeightUnitType.dram:
      factor = 28672;
      break;
    case WeightUnitType.grain:
      factor = 784000;
      break;
    case WeightUnitType.gram:
      factor = 50802.34544;
      break;
    case WeightUnitType.hectogram:
      factor = 508.0234544;
      break;
    case WeightUnitType.hundredWeightLongUk:
      factor = 1;
      break;
    case WeightUnitType.hunderdWeightShortUs:
      factor = 1.12;
      break;
    case WeightUnitType.kilogram:
      factor = 50.80234544;
      break;
    case WeightUnitType.lbLbs:
      factor = 112;
      break;
    case WeightUnitType.longTon:
      factor = 0.05;
      break;
    case WeightUnitType.megagram:
      factor = 0.05080234544;
      break;
    case WeightUnitType.metricTon:
      factor = 0.05080234544;
      break;
    case WeightUnitType.microgram:
      factor = 50802345440;
      break;
    case WeightUnitType.milligram:
      factor = 50802345.44;
      break;
    case WeightUnitType.ounce:
      factor = 1792;
      break;
    case WeightUnitType.pound:
      factor = 112;
      break;
    case WeightUnitType.shortTon:
      factor = 0.056;
      break;
    case WeightUnitType.tonLongUk:
      factor = 0.05;
      break;
    case WeightUnitType.tonMetric:
      factor = 0.05080234544;
      break;
    case WeightUnitType.tonShortUs:
      factor = 0.05080234544;
      break;
    case WeightUnitType.tonne:
      factor = 0.05080234544;
      break;
  }
  return round(inputValue * factor, 50);
}

function hundredweightShortUsConversions(
  inputValue: number,
  outputType: WeightUnitType
) {
  var factor = 0.0;
  switch (outputType) {
    case WeightUnitType.centigram:
      factor = 4535923.7;
      break;
    case WeightUnitType.decigram:
      factor = 453592.37;
      break;
    case WeightUnitType.dekagram:
      factor = 4535.9237;
      break;
    case WeightUnitType.dram:
      factor = 25600;
      break;
    case WeightUnitType.grain:
      factor = 700000;
      break;
    case WeightUnitType.gram:
      factor = 45359.237;
      break;
    case WeightUnitType.hectogram:
      factor = 453.59237;
      break;
    case WeightUnitType.hundredWeightLongUk:
      factor = 0.89285714286;
      break;
    case WeightUnitType.hunderdWeightShortUs:
      factor = 1;
      break;
    case WeightUnitType.kilogram:
      factor = 45.359237;
      break;
    case WeightUnitType.lbLbs:
      factor = 100;
      break;
    case WeightUnitType.longTon:
      factor = 0.044642857143;
      break;
    case WeightUnitType.megagram:
      factor = 0.045359237;
      break;
    case WeightUnitType.metricTon:
      factor = 0.045359237;
      break;
    case WeightUnitType.microgram:
      factor = 45359237000;
      break;
    case WeightUnitType.milligram:
      factor = 45359237;
      break;
    case WeightUnitType.ounce:
      factor = 1600;
      break;
    case WeightUnitType.pound:
      factor = 100;
      break;
    case WeightUnitType.shortTon:
      factor = 0.05;
      break;
    case WeightUnitType.tonLongUk:
      factor = 0.044642857143;
      break;
    case WeightUnitType.tonMetric:
      factor = 0.045359237;
      break;
    case WeightUnitType.tonShortUs:
      factor = 0.05;
      break;
    case WeightUnitType.tonne:
      factor = 0.045359237;
      break;
  }
  return round(inputValue * factor, 50);
}

function kilogramConversions(inputValue: number, outputType: WeightUnitType) {
  var factor = 0.0;
  switch (outputType) {
    case WeightUnitType.centigram:
      factor = 100000;
      break;
    case WeightUnitType.decigram:
      factor = 10000;
      break;
    case WeightUnitType.dekagram:
      factor = 100;
      break;
    case WeightUnitType.dram:
      factor = 564.3833912;
      break;
    case WeightUnitType.grain:
      factor = 15432.358353;
      break;
    case WeightUnitType.gram:
      factor = 1000;
      break;
    case WeightUnitType.hectogram:
      factor = 10;
      break;
    case WeightUnitType.hundredWeightLongUk:
      factor = 0.019684130552;
      break;
    case WeightUnitType.hunderdWeightShortUs:
      factor = 0.022046226218;
      break;
    case WeightUnitType.kilogram:
      factor = 1;
      break;
    case WeightUnitType.lbLbs:
      factor = 2.2046226218;
      break;
    case WeightUnitType.longTon:
      factor = 0.00098420652761;
      break;
    case WeightUnitType.megagram:
      factor = 0.001;
      break;
    case WeightUnitType.metricTon:
      factor = 0.001;
      break;
    case WeightUnitType.microgram:
      factor = 1000000000;
      break;
    case WeightUnitType.milligram:
      factor = 1000000;
      break;
    case WeightUnitType.ounce:
      factor = 35.27396195;
      break;
    case WeightUnitType.pound:
      factor = 2.2046226218;
      break;
    case WeightUnitType.shortTon:
      factor = 0.0011023113109;
      break;
    case WeightUnitType.tonLongUk:
      factor = 0.00098420652761;
      break;
    case WeightUnitType.tonMetric:
      factor = 0.001;
      break;
    case WeightUnitType.tonShortUs:
      factor = 0.0011023113109;
      break;
    case WeightUnitType.tonne:
      factor = 0.001;
      break;
  }
  return round(inputValue * factor, 50);
}

function lbLbsConversions(inputValue: number, outputType: WeightUnitType) {
  var factor = 0.0;
  switch (outputType) {
    case WeightUnitType.centigram:
      factor = 45359.237;
      break;
    case WeightUnitType.decigram:
      factor = 4535.9237;
      break;
    case WeightUnitType.dekagram:
      factor = 45.359237;
      break;
    case WeightUnitType.dram:
      factor = 256;
      break;
    case WeightUnitType.grain:
      factor = 7000;
      break;
    case WeightUnitType.gram:
      factor = 453.59237;
      break;
    case WeightUnitType.hectogram:
      factor = 4.5359237;
      break;
    case WeightUnitType.hundredWeightLongUk:
      factor = 0.0089285714286;
      break;
    case WeightUnitType.hunderdWeightShortUs:
      factor = 0.01;
      break;
    case WeightUnitType.kilogram:
      factor = 0.45359237;
      break;
    case WeightUnitType.lbLbs:
      factor = 1;
      break;
    case WeightUnitType.longTon:
      factor = 0.00044642857143;
      break;
    case WeightUnitType.megagram:
      factor = 0.00045359237;
      break;
    case WeightUnitType.metricTon:
      factor = 0.00045359237;
      break;
    case WeightUnitType.microgram:
      factor = 453592370;
      break;
    case WeightUnitType.milligram:
      factor = 453592.37;
      break;
    case WeightUnitType.ounce:
      factor = 16;
      break;
    case WeightUnitType.pound:
      factor = 1;
      break;
    case WeightUnitType.shortTon:
      factor = 0.0005;
      break;
    case WeightUnitType.tonLongUk:
      factor = 0.00044642857143;
      break;
    case WeightUnitType.tonMetric:
      factor = 0.00045359237;
      break;
    case WeightUnitType.tonShortUs:
      factor = 0.0005;
      break;
    case WeightUnitType.tonne:
      factor = 0.00045359237;
      break;
  }
  return round(inputValue * factor, 50);
}

function longTonConversions(inputValue: number, outputType: WeightUnitType) {
  var factor = 0.0;
  switch (outputType) {
    case WeightUnitType.centigram:
      factor = 101604690.88;
      break;
    case WeightUnitType.decigram:
      factor = 10160469.088;
      break;
    case WeightUnitType.dekagram:
      factor = 101604.69088;
      break;
    case WeightUnitType.dram:
      factor = 573440;
      break;
    case WeightUnitType.grain:
      factor = 15680000;
      break;
    case WeightUnitType.gram:
      factor = 1016046.9088;
      break;
    case WeightUnitType.hectogram:
      factor = 10160.469088;
      break;
    case WeightUnitType.hundredWeightLongUk:
      factor = 20;
      break;
    case WeightUnitType.hunderdWeightShortUs:
      factor = 22.4;
      break;
    case WeightUnitType.kilogram:
      factor = 1016.0469088;
      break;
    case WeightUnitType.lbLbs:
      factor = 2240;
      break;
    case WeightUnitType.longTon:
      factor = 1;
      break;
    case WeightUnitType.megagram:
      factor = 1.0160469088;
      break;
    case WeightUnitType.metricTon:
      factor = 1.0160469088;
      break;
    case WeightUnitType.microgram:
      factor = 1016046908800;
      break;
    case WeightUnitType.milligram:
      factor = 1016046908.8;
      break;
    case WeightUnitType.ounce:
      factor = 35840;
      break;
    case WeightUnitType.pound:
      factor = 2240;
      break;
    case WeightUnitType.shortTon:
      factor = 1.12;
      break;
    case WeightUnitType.tonLongUk:
      factor = 1;
      break;
    case WeightUnitType.tonMetric:
      factor = 1.0160469088;
      break;
    case WeightUnitType.tonShortUs:
      factor = 1.12;
      break;
    case WeightUnitType.tonne:
      factor = 1.0160469088;
      break;
  }
  return round(inputValue * factor, 50);
}

function megagramConversions(inputValue: number, outputType: WeightUnitType) {
  var factor = 0.0;
  switch (outputType) {
    case WeightUnitType.centigram:
      factor = 100000000;
      break;
    case WeightUnitType.decigram:
      factor = 10000000;
      break;
    case WeightUnitType.dekagram:
      factor = 100000;
      break;
    case WeightUnitType.dram:
      factor = 564383.3912;
      break;
    case WeightUnitType.grain:
      factor = 15432358.353;
      break;
    case WeightUnitType.gram:
      factor = 1000000;
      break;
    case WeightUnitType.hectogram:
      factor = 10000;
      break;
    case WeightUnitType.hundredWeightLongUk:
      factor = 19.684130552;
      break;
    case WeightUnitType.hunderdWeightShortUs:
      factor = 22.046226218;
      break;
    case WeightUnitType.kilogram:
      factor = 1000;
      break;
    case WeightUnitType.lbLbs:
      factor = 2204.6226218;
      break;
    case WeightUnitType.longTon:
      factor = 0.98420652761;
      break;
    case WeightUnitType.megagram:
      factor = 1;
      break;
    case WeightUnitType.metricTon:
      factor = 1;
      break;
    case WeightUnitType.microgram:
      factor = 1000000000000;
      break;
    case WeightUnitType.milligram:
      factor = 1000000000;
      break;
    case WeightUnitType.ounce:
      factor = 35273.96195;
      break;
    case WeightUnitType.pound:
      factor = 2204.6226218;
      break;
    case WeightUnitType.shortTon:
      factor = 1.1023113109;
      break;
    case WeightUnitType.tonLongUk:
      factor = 0.98420652761;
      break;
    case WeightUnitType.tonMetric:
      factor = 1;
      break;
    case WeightUnitType.tonShortUs:
      factor = 1.1023113109;
      break;
    case WeightUnitType.tonne:
      factor = 1;
      break;
  }
  return round(inputValue * factor, 50);
}

function metricTonConversions(inputValue: number, outputType: WeightUnitType) {
  var factor = 0.0;
  switch (outputType) {
    case WeightUnitType.centigram:
      factor = 100000000;
      break;
    case WeightUnitType.decigram:
      factor = 10000000;
      break;
    case WeightUnitType.dekagram:
      factor = 100000;
      break;
    case WeightUnitType.dram:
      factor = 564383.3912;
      break;
    case WeightUnitType.grain:
      factor = 15432358.353;
      break;
    case WeightUnitType.gram:
      factor = 1000000;
      break;
    case WeightUnitType.hectogram:
      factor = 10000;
      break;
    case WeightUnitType.hundredWeightLongUk:
      factor = 19.684130552;
      break;
    case WeightUnitType.hunderdWeightShortUs:
      factor = 22.046226218;
      break;
    case WeightUnitType.kilogram:
      factor = 1000;
      break;
    case WeightUnitType.lbLbs:
      factor = 2204.6226218;
      break;
    case WeightUnitType.longTon:
      factor = 0.98420652761;
      break;
    case WeightUnitType.megagram:
      factor = 1;
      break;
    case WeightUnitType.metricTon:
      factor = 1;
      break;
    case WeightUnitType.microgram:
      factor = 1000000000000;
      break;
    case WeightUnitType.milligram:
      factor = 1000000000;
      break;
    case WeightUnitType.ounce:
      factor = 35273.96195;
      break;
    case WeightUnitType.pound:
      factor = 2204.6226218;
      break;
    case WeightUnitType.shortTon:
      factor = 1.1023113109;
      break;
    case WeightUnitType.tonLongUk:
      factor = 0.98420652761;
      break;
    case WeightUnitType.tonMetric:
      factor = 1;
      break;
    case WeightUnitType.tonShortUs:
      factor = 1.1023113109;
      break;
    case WeightUnitType.tonne:
      factor = 1;
      break;
  }
  return round(inputValue * factor, 50);
}

function microGramConversions(inputValue: number, outputType: WeightUnitType) {
  var factor = 0.0;
  switch (outputType) {
    case WeightUnitType.centigram:
      factor = 0.0001;
      break;
    case WeightUnitType.decigram:
      factor = 0.00001;
      break;
    case WeightUnitType.dekagram:
      factor = 1.0e-7;
      break;
    case WeightUnitType.dram:
      factor = 5.643833912e-7;
      break;
    case WeightUnitType.grain:
      factor = 0.000015432358353;
      break;
    case WeightUnitType.gram:
      factor = 0.000001;
      break;
    case WeightUnitType.hectogram:
      factor = 1.0e-8;
      break;
    case WeightUnitType.hundredWeightLongUk:
      factor = 1.9684130552e-11;
      break;
    case WeightUnitType.hunderdWeightShortUs:
      factor = 2.2046226218e-11;
      break;
    case WeightUnitType.kilogram:
      factor = 1.0e-9;
      break;
    case WeightUnitType.lbLbs:
      factor = 2.2046226218e-9;
      break;
    case WeightUnitType.longTon:
      factor = 9.8420652761e-13;
      break;
    case WeightUnitType.megagram:
      factor = 1.0e-12;
      break;
    case WeightUnitType.metricTon:
      factor = 1.0e-12;
      break;
    case WeightUnitType.microgram:
      factor = 1;
      break;
    case WeightUnitType.milligram:
      factor = 0.001;
      break;
    case WeightUnitType.ounce:
      factor = 3.527396195e-8;
      break;
    case WeightUnitType.pound:
      factor = 2.2046226218e-9;
      break;
    case WeightUnitType.shortTon:
      factor = 1.1023113109e-12;
      break;
    case WeightUnitType.tonLongUk:
      factor = 9.8420652761e-13;
      break;
    case WeightUnitType.tonMetric:
      factor = 1.0e-12;
      break;
    case WeightUnitType.tonShortUs:
      factor = 1.1023113109e-12;
      break;
    case WeightUnitType.tonne:
      factor = 1.0e-12;
      break;
  }
  return round(inputValue * factor, 50);
}

function milligramConversions(inputValue: number, outputType: WeightUnitType) {
  var factor = 0.0;
  switch (outputType) {
    case WeightUnitType.centigram:
      factor = 0.1;
      break;
    case WeightUnitType.decigram:
      factor = 0.01;
      break;
    case WeightUnitType.dekagram:
      factor = 0.0001;
      break;
    case WeightUnitType.dram:
      factor = 0.0005643833912;
      break;
    case WeightUnitType.grain:
      factor = 0.015432358353;
      break;
    case WeightUnitType.gram:
      factor = 0.001;
      break;
    case WeightUnitType.hectogram:
      factor = 0.00001;
      break;
    case WeightUnitType.hundredWeightLongUk:
      factor = 1.9684130552e-8;
      break;
    case WeightUnitType.hunderdWeightShortUs:
      factor = 2.2046226218e-8;
      break;
    case WeightUnitType.kilogram:
      factor = 0.000001;
      break;
    case WeightUnitType.lbLbs:
      factor = 0.0000022046226218;
      break;
    case WeightUnitType.longTon:
      factor = 9.8420652761e-10;
      break;
    case WeightUnitType.megagram:
      factor = 1.0e-9;
      break;
    case WeightUnitType.metricTon:
      factor = 1.0e-9;
      break;
    case WeightUnitType.microgram:
      factor = 1000;
      break;
    case WeightUnitType.milligram:
      factor = 1;
      break;
    case WeightUnitType.ounce:
      factor = 0.00003527396195;
      break;
    case WeightUnitType.pound:
      factor = 0.0000022046226218;
      break;
    case WeightUnitType.shortTon:
      factor = 1.1023113109e-9;
      break;
    case WeightUnitType.tonLongUk:
      factor = 9.8420652761e-10;
      break;
    case WeightUnitType.tonMetric:
      factor = 1.0e-9;
      break;
    case WeightUnitType.tonShortUs:
      factor = 1.1023113109e-9;
      break;
    case WeightUnitType.tonne:
      factor = 1.0e-9;
      break;
  }
  return round(inputValue * factor, 50);
}

function ounceConversions(inputValue: number, outputType: WeightUnitType) {
  var factor = 0.0;
  switch (outputType) {
    case WeightUnitType.centigram:
      factor = 2834.9523125;
      break;
    case WeightUnitType.decigram:
      factor = 283.49523125;
      break;
    case WeightUnitType.dekagram:
      factor = 2.8349523125;
      break;
    case WeightUnitType.dram:
      factor = 16;
      break;
    case WeightUnitType.grain:
      factor = 437.5;
      break;
    case WeightUnitType.gram:
      factor = 28.349523125;
      break;
    case WeightUnitType.hectogram:
      factor = 0.28349523125;
      break;
    case WeightUnitType.hundredWeightLongUk:
      factor = 0.00055803571429;
      break;
    case WeightUnitType.hunderdWeightShortUs:
      factor = 0.000625;
      break;
    case WeightUnitType.kilogram:
      factor = 0.028349523125;
      break;
    case WeightUnitType.lbLbs:
      factor = 0.0625;
      break;
    case WeightUnitType.longTon:
      factor = 0.000027901785714;
      break;
    case WeightUnitType.megagram:
      factor = 0.000028349523125;
      break;
    case WeightUnitType.metricTon:
      factor = 0.000028349523125;
      break;
    case WeightUnitType.microgram:
      factor = 28349523.125;
      break;
    case WeightUnitType.milligram:
      factor = 28349.523125;
      break;
    case WeightUnitType.ounce:
      factor = 1;
      break;
    case WeightUnitType.pound:
      factor = 0.0625;
      break;
    case WeightUnitType.shortTon:
      factor = 0.00003125;
      break;
    case WeightUnitType.tonLongUk:
      factor = 0.000027901785714;
      break;
    case WeightUnitType.tonMetric:
      factor = 0.000028349523125;
      break;
    case WeightUnitType.tonShortUs:
      factor = 0.00003125;
      break;
    case WeightUnitType.tonne:
      factor = 0.000028349523125;
      break;
  }
  return round(inputValue * factor, 50);
}

function poundConversions(inputValue: number, outputType: WeightUnitType) {
  var factor = 0.0;
  switch (outputType) {
    case WeightUnitType.centigram:
      factor = 45359.237;
      break;
    case WeightUnitType.decigram:
      factor = 4535.9237;
      break;
    case WeightUnitType.dekagram:
      factor = 45.359237;
      break;
    case WeightUnitType.dram:
      factor = 256;
      break;
    case WeightUnitType.grain:
      factor = 7000;
      break;
    case WeightUnitType.gram:
      factor = 453.59237;
      break;
    case WeightUnitType.hectogram:
      factor = 4.5359237;
      break;
    case WeightUnitType.hundredWeightLongUk:
      factor = 0.0089285714286;
      break;
    case WeightUnitType.hunderdWeightShortUs:
      factor = 0.01;
      break;
    case WeightUnitType.kilogram:
      factor = 0.45359237;
      break;
    case WeightUnitType.lbLbs:
      factor = 1;
      break;
    case WeightUnitType.longTon:
      factor = 0.00044642857143;
      break;
    case WeightUnitType.megagram:
      factor = 0.00045359237;
      break;
    case WeightUnitType.metricTon:
      factor = 0.00045359237;
      break;
    case WeightUnitType.microgram:
      factor = 453592370;
      break;
    case WeightUnitType.milligram:
      factor = 453592.37;
      break;
    case WeightUnitType.ounce:
      factor = 16;
      break;
    case WeightUnitType.pound:
      factor = 1;
      break;
    case WeightUnitType.shortTon:
      factor = 0.0005;
      break;
    case WeightUnitType.tonLongUk:
      factor = 0.00044642857143;
      break;
    case WeightUnitType.tonMetric:
      factor = 0.00045359237;
      break;
    case WeightUnitType.tonShortUs:
      factor = 0.0005;
      break;
    case WeightUnitType.tonne:
      factor = 0.00045359237;
      break;
  }
  return round(inputValue * factor, 50);
}

function shortTonConversions(inputValue: number, outputType: WeightUnitType) {
  var factor = 0.0;
  switch (outputType) {
    case WeightUnitType.centigram:
      factor = 90718474;
      break;
    case WeightUnitType.decigram:
      factor = 9071847.4;
      break;
    case WeightUnitType.dekagram:
      factor = 90718.474;
      break;
    case WeightUnitType.dram:
      factor = 512000;
      break;
    case WeightUnitType.grain:
      factor = 14000000;
      break;
    case WeightUnitType.gram:
      factor = 907184.74;
      break;
    case WeightUnitType.hectogram:
      factor = 9071.8474;
      break;
    case WeightUnitType.hundredWeightLongUk:
      factor = 17.857142857;
      break;
    case WeightUnitType.hunderdWeightShortUs:
      factor = 20;
      break;
    case WeightUnitType.kilogram:
      factor = 907.18474;
      break;
    case WeightUnitType.lbLbs:
      factor = 2000;
      break;
    case WeightUnitType.longTon:
      factor = 0.89285714286;
      break;
    case WeightUnitType.megagram:
      factor = 0.90718474;
      break;
    case WeightUnitType.metricTon:
      factor = 0.90718474;
      break;
    case WeightUnitType.microgram:
      factor = 907184740000;
      break;
    case WeightUnitType.milligram:
      factor = 907184740;
      break;
    case WeightUnitType.ounce:
      factor = 32000;
      break;
    case WeightUnitType.pound:
      factor = 2000;
      break;
    case WeightUnitType.shortTon:
      factor = 1;
      break;
    case WeightUnitType.tonLongUk:
      factor = 0.89285714286;
      break;
    case WeightUnitType.tonMetric:
      factor = 0.90718474;
      break;
    case WeightUnitType.tonShortUs:
      factor = 1;
      break;
    case WeightUnitType.tonne:
      factor = 0.90718474;
      break;
  }
  return round(inputValue * factor, 50);
}

function tonLongUkConversions(inputValue: number, outputType: WeightUnitType) {
  var factor = 0.0;
  switch (outputType) {
    case WeightUnitType.centigram:
      factor = 101604690.88;
      break;
    case WeightUnitType.decigram:
      factor = 10160469.088;
      break;
    case WeightUnitType.dekagram:
      factor = 101604.69088;
      break;
    case WeightUnitType.dram:
      factor = 573440;
      break;
    case WeightUnitType.grain:
      factor = 15680000;
      break;
    case WeightUnitType.gram:
      factor = 1016046.9088;
      break;
    case WeightUnitType.hectogram:
      factor = 10160.469088;
      break;
    case WeightUnitType.hundredWeightLongUk:
      factor = 20;
      break;
    case WeightUnitType.hunderdWeightShortUs:
      factor = 22.4;
      break;
    case WeightUnitType.kilogram:
      factor = 1016.0469088;
      break;
    case WeightUnitType.lbLbs:
      factor = 2240;
      break;
    case WeightUnitType.longTon:
      factor = 1;
      break;
    case WeightUnitType.megagram:
      factor = 1.0160469088;
      break;
    case WeightUnitType.metricTon:
      factor = 1.0160469088;
      break;
    case WeightUnitType.microgram:
      factor = 1016046908800;
      break;
    case WeightUnitType.milligram:
      factor = 1016046908.8;
      break;
    case WeightUnitType.ounce:
      factor = 35840;
      break;
    case WeightUnitType.pound:
      factor = 2240;
      break;
    case WeightUnitType.shortTon:
      factor = 1.12;
      break;
    case WeightUnitType.tonLongUk:
      factor = 1;
      break;
    case WeightUnitType.tonMetric:
      factor = 1.0160469088;
      break;
    case WeightUnitType.tonShortUs:
      factor = 1.12;
      break;
    case WeightUnitType.tonne:
      factor = 1.0160469088;
      break;
  }
  return round(inputValue * factor, 50);
}

function tonMetricConversions(inputValue: number, outputType: WeightUnitType) {
  var factor = 0.0;
  switch (outputType) {
    case WeightUnitType.centigram:
      factor = 100000000;
      break;
    case WeightUnitType.decigram:
      factor = 10000000;
      break;
    case WeightUnitType.dekagram:
      factor = 100000;
      break;
    case WeightUnitType.dram:
      factor = 564383.3912;
      break;
    case WeightUnitType.grain:
      factor = 15432358.353;
      break;
    case WeightUnitType.gram:
      factor = 1000000;
      break;
    case WeightUnitType.hectogram:
      factor = 10000;
      break;
    case WeightUnitType.hundredWeightLongUk:
      factor = 19.684130552;
      break;
    case WeightUnitType.hunderdWeightShortUs:
      factor = 22.046226218;
      break;
    case WeightUnitType.kilogram:
      factor = 1000;
      break;
    case WeightUnitType.lbLbs:
      factor = 2204.6226218;
      break;
    case WeightUnitType.longTon:
      factor = 0.98420652761;
      break;
    case WeightUnitType.megagram:
      factor = 1;
      break;
    case WeightUnitType.metricTon:
      factor = 1;
      break;
    case WeightUnitType.microgram:
      factor = 1000000000000;
      break;
    case WeightUnitType.milligram:
      factor = 1000000000;
      break;
    case WeightUnitType.ounce:
      factor = 35273.96195;
      break;
    case WeightUnitType.pound:
      factor = 2204.6226218;
      break;
    case WeightUnitType.shortTon:
      factor = 1.1023113109;
      break;
    case WeightUnitType.tonLongUk:
      factor = 0.98420652761;
      break;
    case WeightUnitType.tonMetric:
      factor = 1;
      break;
    case WeightUnitType.tonShortUs:
      factor = 1.1023113109;
      break;
    case WeightUnitType.tonne:
      factor = 1;
      break;
  }
  return round(inputValue * factor, 50);
}

function tonShortUsConversions(inputValue: number, outputType: WeightUnitType) {
  var factor = 0.0;
  switch (outputType) {
    case WeightUnitType.centigram:
      factor = 90718474;
      break;
    case WeightUnitType.decigram:
      factor = 9071847.4;
      break;
    case WeightUnitType.dekagram:
      factor = 90718.474;
      break;
    case WeightUnitType.dram:
      factor = 512000;
      break;
    case WeightUnitType.grain:
      factor = 14000000;
      break;
    case WeightUnitType.gram:
      factor = 907184.74;
      break;
    case WeightUnitType.hectogram:
      factor = 9071.8474;
      break;
    case WeightUnitType.hundredWeightLongUk:
      factor = 17.857142857;
      break;
    case WeightUnitType.hunderdWeightShortUs:
      factor = 20;
      break;
    case WeightUnitType.kilogram:
      factor = 907.18474;
      break;
    case WeightUnitType.lbLbs:
      factor = 2000;
      break;
    case WeightUnitType.longTon:
      factor = 0.89285714286;
      break;
    case WeightUnitType.megagram:
      factor = 0.90718474;
      break;
    case WeightUnitType.metricTon:
      factor = 0.90718474;
      break;
    case WeightUnitType.microgram:
      factor = 907184740000;
      break;
    case WeightUnitType.milligram:
      factor = 907184740;
      break;
    case WeightUnitType.ounce:
      factor = 32000;
      break;
    case WeightUnitType.pound:
      factor = 2000;
      break;
    case WeightUnitType.shortTon:
      factor = 1;
      break;
    case WeightUnitType.tonLongUk:
      factor = 0.89285714286;
      break;
    case WeightUnitType.tonMetric:
      factor = 0.90718474;
      break;
    case WeightUnitType.tonShortUs:
      factor = 1;
      break;
    case WeightUnitType.tonne:
      factor = 0.90718474;
      break;
  }
  return round(inputValue * factor, 50);
}

function tonneConversions(inputValue: number, outputType: WeightUnitType) {
  var factor = 0.0;
  switch (outputType) {
    case WeightUnitType.centigram:
      factor = 100000000;
      break;
    case WeightUnitType.decigram:
      factor = 10000000;
      break;
    case WeightUnitType.dekagram:
      factor = 100000;
      break;
    case WeightUnitType.dram:
      factor = 564383.3912;
      break;
    case WeightUnitType.grain:
      factor = 15432358.353;
      break;
    case WeightUnitType.gram:
      factor = 1000000;
      break;
    case WeightUnitType.hectogram:
      factor = 10000;
      break;
    case WeightUnitType.hundredWeightLongUk:
      factor = 19.684130552;
      break;
    case WeightUnitType.hunderdWeightShortUs:
      factor = 22.046226218;
      break;
    case WeightUnitType.kilogram:
      factor = 1000;
      break;
    case WeightUnitType.lbLbs:
      factor = 2204.6226218;
      break;
    case WeightUnitType.longTon:
      factor = 0.98420652761;
      break;
    case WeightUnitType.megagram:
      factor = 1;
      break;
    case WeightUnitType.metricTon:
      factor = 1;
      break;
    case WeightUnitType.microgram:
      factor = 1000000000000;
      break;
    case WeightUnitType.milligram:
      factor = 1000000000;
      break;
    case WeightUnitType.ounce:
      factor = 35273.96195;
      break;
    case WeightUnitType.pound:
      factor = 2204.6226218;
      break;
    case WeightUnitType.shortTon:
      factor = 1.1023113109;
      break;
    case WeightUnitType.tonLongUk:
      factor = 0.98420652761;
      break;
    case WeightUnitType.tonMetric:
      factor = 1;
      break;
    case WeightUnitType.tonShortUs:
      factor = 1.1023113109;
      break;
    case WeightUnitType.tonne:
      factor = 1;
      break;
  }
  return round(inputValue * factor, 50);
}

function getSymbol(outputType: WeightUnitType) {
  switch (outputType) {
    case WeightUnitType.centigram:
      return "cg";
    case WeightUnitType.dekagram:
      return "dag";
    case WeightUnitType.decigram:
      return "dg";
    case WeightUnitType.dram:
      return "ℨ";
    case WeightUnitType.grain:
      return "gr";
    case WeightUnitType.gram:
      return "g";
    case WeightUnitType.hectogram:
      return "hg";
    case WeightUnitType.hunderdWeightShortUs:
      return "[CWT short US]";
    case WeightUnitType.hundredWeightLongUk:
      return "[CWT long UK]";
    case WeightUnitType.kilogram:
      return "kg";
    case WeightUnitType.lbLbs:
      return "LB LBS";
    case WeightUnitType.longTon:
      return "LT";
    case WeightUnitType.megagram:
      return "Mg";
    case WeightUnitType.metricTon:
      return "Metric Ton";
    case WeightUnitType.microgram:
      return "μg";
    case WeightUnitType.milligram:
      return "mg";
    case WeightUnitType.ounce:
      return "oz";
    case WeightUnitType.pound:
      return "lb";
    case WeightUnitType.shortTon:
      return "tn";
    case WeightUnitType.tonLongUk:
      return "[Ton long UK]";
    case WeightUnitType.tonMetric:
      return "Ton Metric";
    case WeightUnitType.tonShortUs:
      return "[tn US]";
    case WeightUnitType.tonne:
      return "t";
    default:
      return "";
  }
}
