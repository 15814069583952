import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  WhenContributionsAreMade,
  calculateTapped,
  compoundInterestFormulaCopiedTapped,
  onAnnualContributionsChanged,
  onCompoundIntervalChanged,
  onCurrencyChanged,
  onInitialInvestmentChanged,
  onInterestRateChanged,
  onMonthlyContributionsChanged,
  onPeriodMonthsChanged,
  onPeriodYearsChanged,
  onWhenContributionsAreMadeChanged,
  resultFutureInvestmentValueCopiedTapped,
  resultTotalContributionsCopiedTapped,
  resultTotalInterestCopiedTapped,
  resultTotalPrincipalCopiedTapped,
  selectState,
} from "./CompoundInterestCalculatorSlice";
import {
  Box,
  InputAdornment,
  MenuItem,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { CustomSelect } from "../../components/custom_select/CustomSelect";
import { CustomButton } from "../../components/custom_button/CustomButton";
import { ResultCard } from "../../components/result_card/ResultCard";
import { CurrencySelect } from "../../components/currency_select/CurrencySelect";
import { CompoundInterval } from "../../utilities/finance";
import { SideMenuPageContainer } from "../../components/side_menu_page_container/SideMenuPageContainer";
import { HomeCard } from "../../components/home_card/HomeCard";
import CreditCardRepaymentCalculatorImage from "../../assets/CreditCardRepaymentCalculator.webp";

export function CompoundInterestCalculator() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectState);
  return (
    <SideMenuPageContainer
      pageTitle="Compound Interest Calculator"
      pageRelativeUrl="CompoundInterestCalculator"
      pageDescription="Discover how to grow your savings with our easy-to-use Compound Interest Calculator, an essential tool for maximizing your investment and savings account potential"
    >
      <Grid2
        container
        spacing={2}
        sx={{ mt: 2 }}
        columns={{ xs: 6, sm: 6, md: 12 }}
      >
        <Grid2 xs={12}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            How much will my investment / savings grow?
          </Typography>
        </Grid2>
        <Grid2 xs={6}>
          <CurrencySelect
            label="Currency"
            value={state.currency}
            onChange={(e) => dispatch(onCurrencyChanged(e.target.value))}
          ></CurrencySelect>
        </Grid2>
        <Grid2 xs={6}>
          <TextField
            fullWidth
            label="Initial Investment"
            variant="outlined"
            color="info"
            value={state.initialInvestment}
            onChange={(e) => {
              dispatch(onInitialInvestmentChanged(Number(e.target.value)));
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>{state.currency}</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid2>
        <Grid2 xs={6}>
          <TextField
            fullWidth
            label="Interest Rate"
            variant="outlined"
            color="info"
            value={state.interestRatePercentage}
            onChange={(e) => {
              dispatch(onInterestRateChanged(Number(e.target.value)));
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography>%</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid2>
        <Grid2 xs={6}>
          <CustomSelect
            label="Compound Interval"
            value={state.compoundInterval}
            onChange={(e) =>
              dispatch(
                onCompoundIntervalChanged(
                  Number(e.target.value) as CompoundInterval
                )
              )
            }
          >
            <MenuItem value={CompoundInterval.daily}>Daily (365/yr)</MenuItem>
            <MenuItem value={CompoundInterval.semiWeekly}>
              Semi-Weekly (104/yr)
            </MenuItem>
            <MenuItem value={CompoundInterval.weekly}>Weekly (52/yr)</MenuItem>
            <MenuItem value={CompoundInterval.biWeekly}>
              Bi-Weekly (26/yr)
            </MenuItem>
            <MenuItem value={CompoundInterval.semiMonthly}>
              Semi-Monthly (24/yr)
            </MenuItem>
            <MenuItem value={CompoundInterval.monthly}>
              Monthly (12/yr)
            </MenuItem>
            <MenuItem value={CompoundInterval.biMonthly}>
              Bi-Monthly (6/yr)
            </MenuItem>
            <MenuItem value={CompoundInterval.quarterly}>
              Quarterly (4/yr)
            </MenuItem>
            <MenuItem value={CompoundInterval.halfYearly}>
              Half-Yearly (2/yr)
            </MenuItem>
            <MenuItem value={CompoundInterval.yearly}>Yearly (1/yr)</MenuItem>
          </CustomSelect>
        </Grid2>
        <Grid2 xs={6}>
          <TextField
            fullWidth
            label="Period (Years)"
            variant="outlined"
            color="info"
            value={state.periodYears}
            onChange={(e) => {
              dispatch(onPeriodYearsChanged(Number(e.target.value)));
            }}
          />
        </Grid2>

        <Grid2 xs={6}>
          <TextField
            fullWidth
            label="Period (Months)"
            variant="outlined"
            color="info"
            value={state.periodMonths}
            onChange={(e) => {
              dispatch(onPeriodMonthsChanged(Number(e.target.value)));
            }}
          />
        </Grid2>

        <Grid2 xs={6}>
          <TextField
            fullWidth
            label="Annual Contributions"
            variant="outlined"
            color="info"
            value={state.annualContributions}
            onChange={(e) => {
              dispatch(onAnnualContributionsChanged(Number(e.target.value)));
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>{state.currency}</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid2>

        <Grid2 xs={6}>
          <TextField
            fullWidth
            label="Monthly Contributions"
            variant="outlined"
            color="info"
            value={state.monthlyContributions}
            onChange={(e) => {
              dispatch(onMonthlyContributionsChanged(Number(e.target.value)));
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>{state.currency}</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid2>
        <Grid2 xs={12}>
          <Typography>
            Contributions added at what point in compound period?
          </Typography>

          <ToggleButtonGroup
            color="secondary"
            value={state.whenContributionsAreMade}
            exclusive
            onChange={(
              event: React.MouseEvent<HTMLElement>,
              newValue: string | null
            ) => {
              dispatch(
                onWhenContributionsAreMadeChanged(
                  Number(newValue) as WhenContributionsAreMade
                )
              );
            }}
            aria-label="text alignment"
          >
            <ToggleButton
              value={WhenContributionsAreMade.beginning}
              aria-label="left aligned"
            >
              <Typography>Beginning</Typography>
            </ToggleButton>
            <ToggleButton
              value={WhenContributionsAreMade.end}
              aria-label="centered"
            >
              <Typography>End</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid2>
        <Grid2 xs={12}>
          <CustomButton
            label="Calculate"
            onClick={() => {
              dispatch(calculateTapped());
            }}
          />
          <Box sx={{ mb: 2 }} />
        </Grid2>
        <Grid2 xs={12}>
          <ResultCard
            value={`${state.resultFutureInvestmentValue}`}
            description="Future Investment Value"
            symbol={state.currency}
            symbolAtStart={true}
            resultCopied={state.resultFutureInvestmentValueCopied}
            onCopy={() => {
              dispatch(resultFutureInvestmentValueCopiedTapped());
            }}
          />
        </Grid2>
        <Grid2 xs={12}>
          <ResultCard
            value={`${state.resultTotalPrincipal}`}
            description="Total Principal"
            symbol={state.currency}
            symbolAtStart={true}
            resultCopied={state.resultTotalPrincipalCopied}
            onCopy={() => {
              dispatch(resultTotalPrincipalCopiedTapped());
            }}
          />
        </Grid2>
        <Grid2 xs={12}>
          <ResultCard
            value={`${state.resultTotalContributions}`}
            description="Total Contributions Made"
            symbol={state.currency}
            symbolAtStart={true}
            resultCopied={state.resultTotalContributionsCopied}
            onCopy={() => {
              dispatch(resultTotalContributionsCopiedTapped());
            }}
          />
        </Grid2>
        <Grid2 xs={12}>
          <ResultCard
            value={`${state.resultTotalInterest}`}
            description="Total Interest Earned"
            symbol={state.currency}
            symbolAtStart={true}
            resultCopied={state.resultTotalInterestCopied}
            onCopy={() => {
              dispatch(resultTotalInterestCopiedTapped());
            }}
          />
        </Grid2>
        <Grid2 xs={12}>
          <Typography variant="h2" sx={{ mt: 5 }}>
            Maximize Your Financial Growth
          </Typography>
          <Typography variant="body1" sx={{ mt: 3 }}>
            Welcome to our Interest Growth Calculator, a tool designed to help
            you visualize the growth potential of your investments in your own
            currency. Understanding the impact of repeated interest accrual on
            your savings is crucial in planning your financial future. Our free
            calculator simplifies this process, providing you with a clear
            picture of how your money can grow over time. Whether you're saving
            for retirement, a major purchase, or just building your emergency
            fund, our calculator is tailored to help you make informed decisions
            about your savings account and investment strategies.
          </Typography>
          <Typography variant="body1" sx={{ mt: 3 }}>
            This tool it's your personal guide in the journey of financial
            growth. By inputting your initial investment amount, the duration of
            investment, and the expected interest rate, you can instantly see
            how compound interest works to increase your savings exponentially.
            Optionally you can also provide additional annual or monthly
            contributions to see how this will affect your savings goal. This
            calculator demonstrates the power of compound interest in
            transforming your initial investment into a substantial sum over the
            years. It's an essential resource for anyone looking to maximize the
            potential of their savings account, making it an invaluable asset
            for both novice savers and seasoned investors alike.
          </Typography>
          <Typography variant="body1" sx={{ mt: 3, mb: 3 }}>
            The result clearly emphasizes the importance of starting early and
            staying consistent with your contributions. The earlier you begin
            saving and investing, the more time your money has to benefit from
            the compounding effect. This is key to achieving long-term financial
            goals.
          </Typography>
          <ResultCard
            value={`A = P(1 + r/n)ⁿᵗ`}
            description="Compound Interest Formula"
            symbol={""}
            resultCopied={state.compoundInterestFormulaCopied}
            onCopy={() => {
              dispatch(compoundInterestFormulaCopiedTapped());
            }}
          />
          <Typography variant="body1" sx={{ mt: 3 }}>
            Compound interest is calculated using the formula above which
            reflects the exponential growth of your investment over time. Where{" "}
            <b style={{ color: "#FFD9A8" }}>A</b> represents the future value of
            the investment/loan, including interest,{" "}
            <b style={{ color: "#FFD9A8" }}>P</b> is the principal amount
            (initial investment), <b style={{ color: "#FFD9A8" }}>r</b> is the
            annual interest rate (decimal),{" "}
            <b style={{ color: "#FFD9A8" }}>n</b> is the number of times
            interest is compounded per year, and{" "}
            <b style={{ color: "#FFD9A8" }}>t</b> is the time the money is
            invested or borrowed for, in years. This formula calculates the
            accumulated amount <b style={{ color: "#FFD9A8" }}>A</b> by adding
            the compound interest to the principal amount, taking into account
            the frequency of compounding. The power of compound interest lies in
            its ability to grow an initial sum into a much larger amount over
            time, as the interest earned in each period is added to the
            principal, creating a snowball effect.
          </Typography>
        </Grid2>
        <Grid2 xs={12}>
          <Typography variant="h3" sx={{ mt:3, mb: 1 }}>
            Related
          </Typography>
        </Grid2>
        <Grid2 xs={6}>
            <HomeCard
              title="Credit Card Repayment Calculator"
              description="Calculate how long it will take to settle a credit card balance, or how much you need to pay monthly to settle within a specified time"
              tooltip="Discover how to get rid of debt with our credit card repayment calculator"
              link="/CreditCardRepaymentCalculator"
              smallCard={true}
              image={CreditCardRepaymentCalculatorImage}
            />
          </Grid2>
      </Grid2>
    </SideMenuPageContainer>
  );
}
