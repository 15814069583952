import {
  BaseInputType,
  binaryResultCopyTapped,
  convertTapped,
  decimalResultCopyTapped,
  fromTypeChanged,
  hexResultCopyTapped,
  inputChanged,
  octalResultCopyTapped,
  selectState,
} from "./BaseConverterSlice";

import {
  Box,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CustomButton } from "../../components/custom_button/CustomButton";
import { EqualColumnTable } from "../../components/equal_column_table/EqualColumnTable";
import { ResultCard } from "../../components/result_card/ResultCard";
import { SideMenuPageContainer } from "../../components/side_menu_page_container/SideMenuPageContainer";
import Grid2 from "@mui/material/Unstable_Grid2";
import { CustomSelect } from "../../components/custom_select/CustomSelect";

export function BaseConverter() {
  const baseConverterState = useAppSelector(selectState);
  const dispatch = useAppDispatch();
  var helpText = "";

  switch (baseConverterState.inputType) {
    case BaseInputType.binary:
      helpText = "10101";
      break;
    case BaseInputType.octal:
      helpText = "10";
      break;
    case BaseInputType.decimal:
      helpText = "15";
      break;
    case BaseInputType.hex:
      helpText = "FF";
      break;
  }
  return (
    <SideMenuPageContainer
      pageTitle="Base Converter"
      pageRelativeUrl="BaseConverter"
      pageDescription="Convert between Decimal, Binary, Hexadecimal and Octal"
    >
      <Grid2
        container
        spacing={2}
        sx={{ mt: 2 }}
        columns={{ xs: 6, sm: 6, md: 12 }}
      >
        <Grid2 xs={12}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Convert Between Binary, Decimal, Hexadecimal and Octal Values
          </Typography>
        </Grid2>
        <Grid2 xs={6}>
          <CustomSelect
            label="From"
            value={baseConverterState.inputType}
            onChange={(e) =>
              dispatch(fromTypeChanged(Number(e.target.value) as BaseInputType))
            }
          >
            <MenuItem value={BaseInputType.binary}>Binary</MenuItem>
            <MenuItem value={BaseInputType.octal}>Octal</MenuItem>
            <MenuItem value={BaseInputType.decimal}>Decimal</MenuItem>
            <MenuItem value={BaseInputType.hex}>Hex</MenuItem>
          </CustomSelect>
        </Grid2>
        <Grid2 xs={6}>
          <TextField
            fullWidth
            label="Value"
            variant="outlined"
            color="info"
            helperText={
              <>
                <Typography variant="helptext" sx={{ mr: 1 }}>
                  ex:
                </Typography>

                <Typography variant="helptext">{helpText}</Typography>
              </>
            }
            value={baseConverterState.input || ""}
            onChange={(e) => {
              dispatch(inputChanged(e.target.value));
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>{baseConverterState.valuePrefix}</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid2>
        <Grid2 xs={12} sx={{ mt: 1, mb: 3 }}>
          <CustomButton
            label="Convert"
            onClick={() => {
              dispatch(convertTapped());
            }}
          />
        </Grid2>
        <Grid2 xs={12}>
          <ResultCard
            value={`0b${baseConverterState.outputBinary}`}
            symbol={``}
            description={"Binary"}
            onCopy={() => dispatch(binaryResultCopyTapped())}
            resultCopied={baseConverterState.binaryResultCopied}
          />
          <ResultCard
            value={`${baseConverterState.outputOctal}`}
            symbol={``}
            description={"Octal"}
            onCopy={() => dispatch(octalResultCopyTapped())}
            resultCopied={baseConverterState.octalResultCopied}
          />
          <ResultCard
            value={`${baseConverterState.outputDecimal}`}
            symbol={``}
            description={"Decimal"}
            onCopy={() => dispatch(decimalResultCopyTapped())}
            resultCopied={baseConverterState.decimalResultCopied}
          />
          <ResultCard
            value={`0x${baseConverterState.outputHex}`}
            symbol={``}
            description={"Hex"}
            onCopy={() => dispatch(hexResultCopyTapped())}
            resultCopied={baseConverterState.hexResultCopied}
          />
        </Grid2>
      </Grid2>
    </SideMenuPageContainer>
  );
}
