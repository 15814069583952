import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { container } from "tsyringe";
import { RootState } from "../../app/store";
import { AnalyticsService } from "../../services/analytics";
import {
  calculateCompoundInterest,
  CompoundInterval,
} from "../../utilities/finance";
import { round } from "../../utilities/maths";

export enum WhenContributionsAreMade {
  beginning,
  end,
}

export interface CompoundInterestCalculatorState {
  currency: string;
  initialInvestment: number;
  interestRatePercentage: number;
  periodYears: number;
  periodMonths: number;
  compoundInterval: CompoundInterval;
  annualContributions: number;
  monthlyContributions: number;
  resultFutureInvestmentValue: number;
  resultTotalPrincipal: number;
  resultTotalContributions: number;
  resultTotalInterest: number;
  resultFutureInvestmentValueCopied: boolean;
  resultTotalPrincipalCopied: boolean;
  resultTotalContributionsCopied: boolean;
  resultTotalInterestCopied: boolean;
  whenContributionsAreMade: WhenContributionsAreMade;
  compoundInterestFormulaCopied: boolean;
}

const initialState: CompoundInterestCalculatorState = {
  currency: "$",
  initialInvestment: 1000,
  interestRatePercentage: 5,
  periodYears: 2,
  periodMonths: 0,
  compoundInterval: CompoundInterval.yearly,
  annualContributions: 0,
  monthlyContributions: 0,
  resultFutureInvestmentValue: 1102.5,
  resultTotalPrincipal: 1000,
  resultTotalContributions: 0,
  resultTotalInterest: 102.5,
  resultFutureInvestmentValueCopied: false,
  resultTotalPrincipalCopied: false,
  resultTotalContributionsCopied: false,
  resultTotalInterestCopied: false,
  whenContributionsAreMade: WhenContributionsAreMade.beginning,
  compoundInterestFormulaCopied: false,
};

export const CompoundInterestCalculatorSlice = createSlice({
  name: "compoundInterestCalculator",
  initialState,
  reducers: {
    onCurrencyChanged: (state, action: PayloadAction<string>) => {
      state.currency = action.payload;
    },
    onInitialInvestmentChanged: (state, action: PayloadAction<number>) => {
      if (Number.isNaN(action.payload)) {
        return;
      }

      state.initialInvestment = action.payload;
    },
    onInterestRateChanged: (state, action: PayloadAction<number>) => {
      if (Number.isNaN(action.payload)) {
        return;
      }

      state.interestRatePercentage = action.payload;
    },
    onPeriodYearsChanged: (state, action: PayloadAction<number>) => {
      if (Number.isNaN(action.payload)) {
        return;
      }

      state.periodYears = action.payload;
    },
    onPeriodMonthsChanged: (state, action: PayloadAction<number>) => {
      if (Number.isNaN(action.payload)) {
        return;
      }

      state.periodMonths = action.payload;
    },
    onCompoundIntervalChanged: (
      state,
      action: PayloadAction<CompoundInterval>
    ) => {
      state.compoundInterval = action.payload;
    },
    onMonthlyContributionsChanged: (state, action: PayloadAction<number>) => {
      if (Number.isNaN(action.payload)) {
        return;
      }

      state.monthlyContributions = action.payload;
    },
    onAnnualContributionsChanged: (state, action: PayloadAction<number>) => {
      if (Number.isNaN(action.payload)) {
        return;
      }

      state.annualContributions = action.payload;
    },
    onWhenContributionsAreMadeChanged: (
      state,
      action: PayloadAction<WhenContributionsAreMade>
    ) => {
      state.whenContributionsAreMade = action.payload;
    },
    resultFutureInvestmentValueCopiedTapped: (state) => {
      state.resultFutureInvestmentValueCopied = true;
      state.resultTotalPrincipalCopied = false;
      state.resultTotalContributionsCopied = false;
      state.resultTotalInterestCopied = false;
      state.compoundInterestFormulaCopied = false;
    },
    resultTotalPrincipalCopiedTapped: (state) => {
      state.resultFutureInvestmentValueCopied = false;
      state.resultTotalPrincipalCopied = true;
      state.resultTotalContributionsCopied = false;
      state.resultTotalInterestCopied = false;
      state.compoundInterestFormulaCopied = false;
    },
    resultTotalContributionsCopiedTapped: (state) => {
      state.resultFutureInvestmentValueCopied = false;
      state.resultTotalPrincipalCopied = false;
      state.resultTotalContributionsCopied = true;
      state.resultTotalInterestCopied = false;
      state.compoundInterestFormulaCopied = false;
    },
    resultTotalInterestCopiedTapped: (state) => {
      state.resultFutureInvestmentValueCopied = false;
      state.resultTotalPrincipalCopied = false;
      state.resultTotalContributionsCopied = false;
      state.resultTotalInterestCopied = true;
      state.compoundInterestFormulaCopied = false;
    },
    compoundInterestFormulaCopiedTapped: (state) => {
      state.resultFutureInvestmentValueCopied = false;
      state.resultTotalPrincipalCopied = false;
      state.resultTotalContributionsCopied = false;
      state.resultTotalInterestCopied = false;
      state.compoundInterestFormulaCopied = true;
    },
    calculateTapped: (state) => {
      const analyticsService = container.resolve(AnalyticsService);
      analyticsService.LogEvent({
        category: "Button Click",
        action: "Convert Clicked",
        label: "Compound Interest Calculator",
      });

      var timeInYears = state.periodYears + state.periodMonths / 12;
      var interestRate = state.interestRatePercentage / 100;
      var totalContributions = 0;

      state.resultFutureInvestmentValueCopied = false;
      state.resultTotalPrincipalCopied = false;
      state.resultTotalContributionsCopied = false;
      state.resultTotalInterestCopied = false;
      state.compoundInterestFormulaCopied = false;

      if (state.annualContributions === 0 && state.monthlyContributions === 0) {
        //No additional contributions
        state.resultFutureInvestmentValue = round(
          calculateCompoundInterest(
            state.initialInvestment,
            interestRate,
            state.compoundInterval,
            timeInYears
          ),
          2
        );
      } else {
        var investmentPeriodTimeInYears = 1;
        if (state.monthlyContributions === 0) {
          //Yearly investments
          investmentPeriodTimeInYears = 1;
        } else {
          //Monthly investments
          investmentPeriodTimeInYears = 1 / 12;
        }

        var remainingTime = timeInYears;
        var totalInvestement = state.initialInvestment;
        var index = 0;

        while (round(remainingTime, 2) > 0) {
          var currentInvestmentPeriodTimeInYears = 0;
          if (remainingTime >= investmentPeriodTimeInYears) {
            currentInvestmentPeriodTimeInYears = investmentPeriodTimeInYears;
            remainingTime -= investmentPeriodTimeInYears;
          } else {
            currentInvestmentPeriodTimeInYears = remainingTime;
            remainingTime = 0;
          }

          if (
            state.whenContributionsAreMade ===
            WhenContributionsAreMade.beginning
          ) {
            if (state.monthlyContributions !== 0) {
              totalInvestement += state.monthlyContributions;
              totalContributions += state.monthlyContributions;
              if (index % 12 == 0) {
                totalInvestement += state.annualContributions;
                totalContributions += state.annualContributions;
              }
            } else {
              totalInvestement += state.annualContributions;
              totalContributions += state.annualContributions;
            }
          }
          index++;
          totalInvestement = calculateCompoundInterest(
            totalInvestement,
            interestRate,
            state.compoundInterval,
            currentInvestmentPeriodTimeInYears
          );

          if (state.whenContributionsAreMade === WhenContributionsAreMade.end) {
            if (state.monthlyContributions !== 0) {
              totalInvestement += state.monthlyContributions;
              totalContributions += state.monthlyContributions;
              if (index % 12 == 0) {
                totalInvestement += state.annualContributions;
                totalContributions += state.annualContributions;
              }
            } else {
              totalInvestement += state.annualContributions;
              totalContributions += state.annualContributions;
            }
          }
        }
        state.resultFutureInvestmentValue = round(totalInvestement, 2);
      }
      state.resultTotalPrincipal = state.initialInvestment + totalContributions;
      state.resultTotalContributions = totalContributions;
      state.resultTotalInterest = round(
        state.resultFutureInvestmentValue - state.resultTotalPrincipal,
        2
      );
    },
  },
});

export const {
  onCurrencyChanged,
  onInitialInvestmentChanged,
  onInterestRateChanged,
  onPeriodYearsChanged,
  onPeriodMonthsChanged,
  onCompoundIntervalChanged,
  onAnnualContributionsChanged,
  onMonthlyContributionsChanged,
  onWhenContributionsAreMadeChanged,
  resultFutureInvestmentValueCopiedTapped,
  resultTotalPrincipalCopiedTapped,
  resultTotalContributionsCopiedTapped,
  resultTotalInterestCopiedTapped,
  compoundInterestFormulaCopiedTapped,
  calculateTapped,
} = CompoundInterestCalculatorSlice.actions;

export const selectState = (state: RootState) =>
  state.compoundInterestCalculator;

export default CompoundInterestCalculatorSlice.reducer;
