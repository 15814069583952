import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface SideMenuState {
}

const initialState: SideMenuState = {
}

export const SideMenuSlice = createSlice({
    name: 'sideMenu',
    initialState,
    reducers: {
    }
});

export const { } = SideMenuSlice.actions;

export const selectState = (state: RootState) => state.sideMenu;

export default SideMenuSlice.reducer;