import ReactGA from 'react-ga4';
import "reflect-metadata";
import { singleton } from "tsyringe";

interface ILogEvent{
    category: string;
    action: string;
    label: string;
}

@singleton()
export class AnalyticsService {
  Initialize(key: string){
    ReactGA.initialize(key);
  }

  LogEvent({category, action, label} : ILogEvent){
    if (process.env!.NODE_ENV !== "production"){
      return;
    }
    ReactGA.event(
        {
            action: action,
            category: category,
            label: label,
        }
    );
  }
}