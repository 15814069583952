import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CurrencySelect } from "../../components/currency_select/CurrencySelect";
import {
  calculateTapped,
  onCompoundInterestFormulaCopiedTapped,
  onCreditCardBalanceChanged,
  onCurrencyChanged,
  onInterestRateChanged,
  onMonthlyRepaymentAmountChanged,
  onResultMonthsToPayOffDebtCopiedTapped,
  onResultTotalInterestPaidCopiedTapped,
  onResultTotalPayBackCopiedTapped,
  selectState,
} from "./CreditCardRepaymentCalculatorSlice";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { CustomButton } from "../../components/custom_button/CustomButton";
import { ResultCard } from "../../components/result_card/ResultCard";
import { SideMenuPageContainer } from "../../components/side_menu_page_container/SideMenuPageContainer";
import CompoundInterestCalculatorImage from "../../assets/CompoundInterestCalculator.webp";
import { HomeCard } from "../../components/home_card/HomeCard";

export function CreditCardRepaymentCalculator() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectState);

  return (
    <SideMenuPageContainer
      pageTitle="Credit Card Repayment Calculator | Estimate Payoff Time & Monthly Payments"
      pageRelativeUrl="CreditCardRepaymentCalculator"
      pageDescription="Calculate how long it will take to settle a credit card balance, or how much you need to pay monthly to settle within a specified time"
    >
      <Grid2
        container
        spacing={2}
        sx={{ mt: 2 }}
        columns={{ xs: 6, sm: 6, md: 12 }}
      >
        <Grid2 xs={12}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            How long it will take to settle my credit card balance?
          </Typography>
        </Grid2>

        <Grid2 xs={6}>
          <CurrencySelect
            label="Currency"
            value={state.currency}
            onChange={(e) => {
              dispatch(onCurrencyChanged(e.target.value));
            }}
          />
        </Grid2>
        <Grid2 xs={6}>
          <TextField
            fullWidth
            label="Credit Card Balance"
            variant="outlined"
            color="info"
            value={state.creditCardBalance}
            onChange={(e) => {
              dispatch(onCreditCardBalanceChanged(Number(e.target.value)));
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>{state.currency}</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid2>
        <Grid2 xs={6}>
          <TextField
            fullWidth
            label="Interest Rate (APR)"
            variant="outlined"
            color="info"
            value={state.interestRatePercentage}
            onChange={(e) => {
              dispatch(onInterestRateChanged(Number(e.target.value)));
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography>%</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid2>
        <Grid2 xs={6}>
          <TextField
            fullWidth
            label="Monthly Repayment Amount"
            variant="outlined"
            color="info"
            value={state.monthlyRepaymentAmount}
            onChange={(e) => {
              dispatch(onMonthlyRepaymentAmountChanged(Number(e.target.value)));
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>{state.currency}</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid2>
        <Grid2 xs={12}>
          <CustomButton
            label="Calculate"
            onClick={() => {
              dispatch(calculateTapped());
            }}
          />
        </Grid2>
        <Grid2>
          <Typography
            variant={"error"}
            sx={{
              display: state.showInstallmentLowerThanCreditError
                ? "inline"
                : "none",
            }}
          >
            Monthly repayment amount is lower than the interest generated in a
            month!
          </Typography>
        </Grid2>
        <Grid2 xs={12}>
          <ResultCard
            value={`${state.resultMonthsToPayOffDebt}`}
            description="Months to Pay Off Debt"
            symbol="Months"
            resultCopied={state.resultMonthsToPayOffDebtCopied}
            onCopy={() => {
              dispatch(onResultMonthsToPayOffDebtCopiedTapped());
            }}
          />
        </Grid2>
        <Grid2 xs={12}>
          <ResultCard
            value={`${state.resultTotalPayBack}`}
            description="Total to Pay Back"
            symbolAtStart={true}
            symbol={state.currency}
            resultCopied={state.resultTotalPayBackCopied}
            onCopy={() => {
              dispatch(onResultTotalPayBackCopiedTapped());
            }}
          />
        </Grid2>
        <Grid2 xs={12}>
          <ResultCard
            value={`${state.resultTotalInterestPaid}`}
            description="Total Interest You Will Pay"
            symbolAtStart={true}
            symbol={state.currency}
            resultCopied={state.resultTotalInterestPaidCopied}
            onCopy={() => {
              dispatch(onResultTotalInterestPaidCopiedTapped());
            }}
          />
        </Grid2>
        <Grid2 xs={12}>
          <Typography variant="h2" sx={{ mt: 5 }}>
            Understanding Your Credit Card Debt
          </Typography>
          <Typography variant="body1" sx={{ mt: 3, mb: 3 }}>
            Our Credit Card Repayment Calculator is a user-friendly tool
            designed to help you understand the timeline and cost associated
            with paying off your credit card debt. By entering details like your
            credit card balance, the annual percentage rate (APR), and your
            preferred monthly repayment amount, the calculator provides valuable
            insights into your debt clearance journey. It computes the number of
            months required to completely pay off your balance, the total amount
            you will end up paying, and the interest accrued over this period.
            This enables you to plan your finances more effectively and make
            informed decisions about managing your credit card debt.
          </Typography>
          <ResultCard
            value={`A = P(1 + r/12)¹²ᵗ`}
            description="Monthly Compound Interest Formula"
            symbol={""}
            resultCopied={state.compoundInterestFormulaCopied}
            onCopy={() => {
              dispatch(onCompoundInterestFormulaCopiedTapped());
            }}
          />
          <Typography variant="body1" sx={{ mt: 3 }}>
            Credit card interest is compounded monthly and is calculated using
            the formula above which shows how quickly your debt can grow if not
            settled as soon as possible. Where{" "}
            <b style={{ color: "#FFD9A8" }}>A</b> represents the future value of
            debt, including interest, <b style={{ color: "#FFD9A8" }}>P</b> is
            the principal amount (initial debt),{" "}
            <b style={{ color: "#FFD9A8" }}>r</b> is the annual interest rate
            (decimal), and <b style={{ color: "#FFD9A8" }}>t</b> is the time the
            money is borrowed for, in years. This formula calculates the
            accumulated amount <b style={{ color: "#FFD9A8" }}>A</b> by adding
            the compound interest to the principal amount, taking into account a
            monthly compounding frequency.
          </Typography>
        </Grid2>
        <Grid2 xs={12}>
          <Typography variant="h3" sx={{ mt:3, mb: 1 }}>
            Related
          </Typography>
        </Grid2>
        <Grid2 xs={6}>
          <HomeCard
                    title="Interest Calculator"
                    description="Discover how to grow your savings with our easy-to-use Compound Interest Calculator, an essential tool for maximizing your investment and savings account potential"
                    tooltip="The ultimate tool to estimate investment growth over time"
                    link="/CompoundInterestCalculator"
                    smallCard={true}
                    image={CompoundInterestCalculatorImage}
                  />
          </Grid2>
      </Grid2>
    </SideMenuPageContainer>
  );
}
