import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { PageContainer } from "../../components/page_container/PageContainer";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import logo from "../../assets/LogoSmall.webp";
import { selectState } from "./HomeSlice";
import {
  AppBar,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { HomeCard } from "../../components/home_card/HomeCard";
import BaseConverterImage from "../../assets/BaseConverter.webp";
import BmiCalculatorImage from "../../assets/BmiCalculator.webp";
import WeightUnitConverterImage from "../../assets/WeightUnitConverter.webp";
import DistanceUnitConverterImage from "../../assets/DistanceUnitConverter.webp";
import FaviconGeneratorImage from "../../assets/FaviconGenerator.webp";
import TemperatureConverterImage from "../../assets/TemperatureConverter.webp";
import CompoundInterestCalculatorImage from "../../assets/CompoundInterestCalculator.webp";
import CreditCardRepaymentCalculatorImage from "../../assets/CreditCardRepaymentCalculator.webp";
import AndroidIosImageResizerImage from "../../assets/AndroidIosImageResizer.webp";

export function Home() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectState);

  return (
    <Stack>
      <AppBar position="fixed">
        <Stack
          direction="row"
          justifyContent="center"
          sx={{ backgroundColor: "#282B39" }}
        >
          <Box sx={{ width: { xs: 110, sm: 130, md: 150, lg: 150 }, ml: -5 }}>
            <img
              src={logo}
              loading="lazy"
              width={110}
              height={120}
              alt="Number Chomper logo"
              className="applogo"
            />
          </Box>

          <Stack direction="column" justifyContent="start">
            <Box
              sx={{
                width: { xs: 200, sm: 250, md: 300, lg: 300 },
                mt: 2,
                ml: 3,
              }}
            >
              <Typography
                variant="h1"
                sx={{ fontSize: { xs: 17, sm: 23, md: 25, lg: 25 } }}
              >
                NUMBERCHOMPER.COM
              </Typography>
              <Typography
                variant="h3"
                sx={{ fontSize: { xs: 10, sm: 11, md: 12, lg: 12 } }}
              >
                Online, Free, and Easy-To-Use Converters, Generators and
                Calculators to Help with Everyday Tasks
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </AppBar>
      <PageContainer
        pageTitle="Number Chomper"
        pageRelativeUrl=""
        pageDescription="Online, Free, and Easy-To-Use Converters, Generators and Calculators to Help with Everyday Tasks"
      >
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            mt: 8,
          }}
        >
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Box sx={{ width: { xs: 300, sm: 600, md: 900, lg: 1200 } }}>
              <Grid2
                container
                spacing={3}
                sx={{ mt: 4 }}
                columns={{ xs: 3, sm: 6, md: 9, lg: 12 }}
              >
                <Grid2 xs={3}>
                  <HomeCard
                    title="Base Converter"
                    description="Convert between binary, decimal, hexadecimal and octal"
                    tooltip="Free, instant conversion: binary to decimal, hex, oct "
                    link="/BaseConverter"
                    image={BaseConverterImage}
                  />
                </Grid2>
                <Grid2 xs={3}>
                  <HomeCard
                    title="BMI Calculator"
                    description="Calculate body-mass index by providing height and weight"
                    tooltip="Accurate, Quick & Easy BMI Calculator - Calculate Your Body Mass Index for Free"
                    link="/BmiCalculator"
                    image={BmiCalculatorImage}
                  />
                </Grid2>
                <Grid2 xs={3}>
                  <HomeCard
                    title="Distance Unit Converter"
                    description="Convert between Millimeter, Centimeter, Meter, Inch and Feet"
                    tooltip="Instant Free Conversion Tool - Mm to inch, m to ft, cm to mm"
                    link="/DistanceUnitConverter"
                    image={DistanceUnitConverterImage}
                  />
                </Grid2>
                <Grid2 xs={3}>
                  <HomeCard
                    title="Weight Unit Converter"
                    description="Convert between Pound, Kilogram, Gram, Ounce, Milligram and other weight measurements"
                    tooltip="Convert Weight Measurements Online - Lb, g, mg, kg, dg, hg, t and more"
                    link="/WeightUnitConverter"
                    image={WeightUnitConverterImage}
                  />
                </Grid2>
                <Grid2 xs={3}>
                  <HomeCard
                    title="Favicon Generator"
                    description="Generate a Website favicon and manifest.json for multiple resolutions to achieve maximum browser support"
                    tooltip="Free online PNG to favicon and manifest.json converter"
                    link="/FaviconGenerator"
                    image={FaviconGeneratorImage}
                  />
                </Grid2>
                <Grid2 xs={3}>
                  <HomeCard
                    title="Temperature Converter"
                    description="Convert between Celsius, Kelvin and Fahrenheit"
                    tooltip="Instantly convert between °C, °F and K"
                    link="/TemperatureConverter"
                    image={TemperatureConverterImage}
                  />
                </Grid2>
                <Grid2 xs={3}>
                  <HomeCard
                    title="Interest Calculator"
                    description="Discover how to grow your savings with our easy-to-use Compound Interest Calculator, an essential tool for maximizing your investment and savings account potential"
                    tooltip="The ultimate tool to estimate investment growth over time"
                    link="/CompoundInterestCalculator"
                    image={CompoundInterestCalculatorImage}
                  />
                </Grid2>
                <Grid2 xs={3}>
                  <HomeCard
                    title="Credit Card Repayment Calculator"
                    description="Calculate how long it will take to settle a credit card balance, or how much you need to pay monthly to settle within a specified time"
                    tooltip="Discover how to get rid of debt with our credit card repayment calculator"
                    link="/CreditCardRepaymentCalculator"
                    image={CreditCardRepaymentCalculatorImage}
                  />
                </Grid2>
                <Grid2 xs={3}>
                  <HomeCard
                    title="Android iOS Image Resizer"
                    description="Resize Android and iOS app images for all screen densities by the click of a button"
                    tooltip="Free and easy, PNG to Android and iOS image assets converter. "
                    link="/AndroidIosImageResizer"
                    image={AndroidIosImageResizerImage}
                  />
                </Grid2>
              </Grid2>
            </Box>
          </Stack>
        </Box>
      </PageContainer>
    </Stack>
  );
}
