import { Box, ButtonBase, Stack, Typography } from "@mui/material";
import React from "react";
import { borderRadius } from "@mui/system";
import { Link } from "react-router-dom";

interface CustomInputProperties {
  title: string;
  description: string;
  link: string;
  tooltip?: string;
  children?: React.ReactNode;
  image: string;
  smallCard?: boolean,
}

export function HomeCard(properties: CustomInputProperties) {

  var smallCard = properties.smallCard ?? false;
  return (
    <Box sx={{ width: (smallCard? "240px" : "280px") , maxWidth: (smallCard? "240px" : "280px") }}>
      <Link to={properties.link} title={properties.tooltip}>
        <ButtonBase
          component="div"
          sx={{ backgroundColor: "#282B39", borderRadius: "20px" }}
        >
          <Box>
            <img
              src={properties.image}
              loading="lazy"
              height={smallCard? 140 : 180}
              width={smallCard? 240 : 280}
              alt={`${properties.title} Image`}
              style={{ borderRadius: "20px 20px 0px 0px", objectFit: "cover" }}
            />
            <Stack sx={{ pl: 2, pr: 2, pt: 1, pb: 2 }}>
              <Typography variant="h4" sx={{
                  textOverflow: "ellipsis",
                  WebkitLineClamp: 1,
                  lineClamp: 1,
                  overflow: "hidden",
                  wordBreak: "break-all",
                  WebkitBoxOrient: "vertical",
                  display: "-webkit-box",
                  fontSize: (smallCard? "19px" : "21px")
              }}>{properties.title}</Typography>
              <Typography
                variant="body2"
                sx={{
                  mt: 1,
                  fontSize: 12,
                  textOverflow: "ellipsis",
                  WebkitLineClamp: 2,
                  lineClamp: 2,
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  display: "-webkit-box"
                }}
              >
                {properties.description}
              </Typography>
            </Stack>
          </Box>
        </ButtonBase>
      </Link>
    </Box>
  );
}
