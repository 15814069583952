import { useAppDispatch, useAppSelector } from "../../app/hooks";
import * as changeCase from "change-case";

import {
  WeightUnitType,
  calculateTapped,
  inputTypeOnChanged,
  inputValueOnChanged,
  outputTypeOnChanged,
  resultCopyTapped,
  selectState,
} from "./WeightUnitConverterSlice";
import {
  Box,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { ResultCard } from "../../components/result_card/ResultCard";
import { CustomButton } from "../../components/custom_button/CustomButton";
import { EqualColumnTable } from "../../components/equal_column_table/EqualColumnTable";
import { SideMenuPageContainer } from "../../components/side_menu_page_container/SideMenuPageContainer";
import Grid2 from "@mui/material/Unstable_Grid2";
import { HomeCard } from "../../components/home_card/HomeCard";
import TemperatureConverterImage from "../../assets/TemperatureConverter.webp";
import DistanceUnitConverterImage from "../../assets/DistanceUnitConverter.webp";
import { CustomSelect } from "../../components/custom_select/CustomSelect";

export function WeightUnitConverter() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectState);

  return (
    <SideMenuPageContainer
      pageTitle="Weight Unit Converter"
      pageRelativeUrl="WeightUnitConverter"
      pageDescription="Convert between Pound, Kilogram, Gram, Ounce, Milligram and other weight measurements"
    >
      <Grid2
        container
        spacing={2}
        sx={{ mt: 2 }}
        columns={{ xs: 6, sm: 6, md: 12 }}
      >
        <Grid2 xs={12}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Convert between Pound, Kilogram, Gram, Ounce, Milligram and More
          </Typography>
        </Grid2>
        <Grid2 xs={6}>
          <CustomSelect
            label="From"
            value={state.inputUnit}
            onChange={(e) =>
              dispatch(
                inputTypeOnChanged(Number(e.target.value) as WeightUnitType)
              )
            }
          >
            <MenuItem value={WeightUnitType.centigram}>Centigram</MenuItem>
            <MenuItem value={WeightUnitType.decigram}>Decigram</MenuItem>
            <MenuItem value={WeightUnitType.dekagram}>Dekagram</MenuItem>
            <MenuItem value={WeightUnitType.dram}>Dram</MenuItem>
            <MenuItem value={WeightUnitType.grain}>Grain</MenuItem>
            <MenuItem value={WeightUnitType.gram}>Gram</MenuItem>
            <MenuItem value={WeightUnitType.hectogram}>Hectogram</MenuItem>
            <MenuItem value={WeightUnitType.hundredWeightLongUk}>
              Hunderd Weight (long, UK)
            </MenuItem>
            <MenuItem value={WeightUnitType.hunderdWeightShortUs}>
              Hundred Weight (short, US)
            </MenuItem>
            <MenuItem value={WeightUnitType.kilogram}>Kilogram</MenuItem>
            <MenuItem value={WeightUnitType.lbLbs}>lb, lbs</MenuItem>
            <MenuItem value={WeightUnitType.longTon}>Long Ton</MenuItem>
            <MenuItem value={WeightUnitType.megagram}>Megagram</MenuItem>
            <MenuItem value={WeightUnitType.metricTon}>Metric Ton</MenuItem>
            <MenuItem value={WeightUnitType.microgram}>Microgram</MenuItem>
            <MenuItem value={WeightUnitType.milligram}>Milligram</MenuItem>
            <MenuItem value={WeightUnitType.ounce}>Ounce</MenuItem>
            <MenuItem value={WeightUnitType.pound}>Pound</MenuItem>
            <MenuItem value={WeightUnitType.shortTon}>Short Ton</MenuItem>
            <MenuItem value={WeightUnitType.tonLongUk}>Ton (long, UK)</MenuItem>
            <MenuItem value={WeightUnitType.tonMetric}>Ton (metric)</MenuItem>
            <MenuItem value={WeightUnitType.tonShortUs}>
              Ton (short, US)
            </MenuItem>
            <MenuItem value={WeightUnitType.tonne}>Tonne</MenuItem>
          </CustomSelect>
        </Grid2>
        <Grid2 xs={6}>
          <CustomSelect
            label="To"
            value={state.outputUnit}
            onChange={(e) =>
              dispatch(
                outputTypeOnChanged(Number(e.target.value) as WeightUnitType)
              )
            }
          >
            <MenuItem value={WeightUnitType.centigram}>Centigram</MenuItem>
            <MenuItem value={WeightUnitType.decigram}>Decigram</MenuItem>
            <MenuItem value={WeightUnitType.dekagram}>Dekagram</MenuItem>
            <MenuItem value={WeightUnitType.dram}>Dram</MenuItem>
            <MenuItem value={WeightUnitType.grain}>Grain</MenuItem>
            <MenuItem value={WeightUnitType.gram}>Gram</MenuItem>
            <MenuItem value={WeightUnitType.hectogram}>Hectogram</MenuItem>
            <MenuItem value={WeightUnitType.hundredWeightLongUk}>
              Hunderd Weight (long, UK)
            </MenuItem>
            <MenuItem value={WeightUnitType.hunderdWeightShortUs}>
              Hundred Weight (short, US)
            </MenuItem>
            <MenuItem value={WeightUnitType.kilogram}>Kilogram</MenuItem>
            <MenuItem value={WeightUnitType.lbLbs}>lb, lbs</MenuItem>
            <MenuItem value={WeightUnitType.longTon}>Long Ton</MenuItem>
            <MenuItem value={WeightUnitType.megagram}>Megagram</MenuItem>
            <MenuItem value={WeightUnitType.metricTon}>Metric Ton</MenuItem>
            <MenuItem value={WeightUnitType.microgram}>Microgram</MenuItem>
            <MenuItem value={WeightUnitType.milligram}>Milligram</MenuItem>
            <MenuItem value={WeightUnitType.ounce}>Ounce</MenuItem>
            <MenuItem value={WeightUnitType.pound}>Pound</MenuItem>
            <MenuItem value={WeightUnitType.shortTon}>Short Ton</MenuItem>
            <MenuItem value={WeightUnitType.tonLongUk}>Ton (long, UK)</MenuItem>
            <MenuItem value={WeightUnitType.tonMetric}>Ton (metric)</MenuItem>
            <MenuItem value={WeightUnitType.tonShortUs}>
              Ton (short, US)
            </MenuItem>
            <MenuItem value={WeightUnitType.tonne}>Tonne</MenuItem>
          </CustomSelect>
        </Grid2>
        <Grid2 xs={6}>
          <TextField
            fullWidth
            label="Value"
            variant="outlined"
            color="info"
            value={state.inputValue || ""}
            onChange={(e) =>
              dispatch(inputValueOnChanged(Number(e.target.value)))
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography>{state.inputSymbol}</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid2>
        <Grid2 xs={12} sx={{ mt: 1, mb: 3 }}>
          <CustomButton
            label="Convert"
            onClick={() => {
              dispatch(calculateTapped());
            }}
          />
        </Grid2>
        <Grid2 xs={12}>
          <ResultCard
            value={`${state.outputValue}`}
            symbol={`${state.resultSymbol}`}
            description={changeCase.pascalCase(
              WeightUnitType[state.resultUnit]
            )}
            onCopy={() => dispatch(resultCopyTapped())}
            resultCopied={state.resultCopied}
          />
        </Grid2>
        <Grid2 xs={12}>
          <Typography variant="h3" sx={{ mt: 3, mb: 1 }}>
            Related
          </Typography>
        </Grid2>
        <Grid2 xs={6}>
          <HomeCard
            title="Distance Unit Converter"
            description="Convert between Millimeter, Centimeter, Meter, Inch and Feet"
            tooltip="Instant Free Conversion Tool - Mm to inch, m to ft, cm to mm"
            link="/DistanceUnitConverter"
            smallCard={true}
            image={DistanceUnitConverterImage}
          />
        </Grid2>
        <Grid2 xs={6}>
          <HomeCard
            title="Temperature Converter"
            description="Convert between Celsius, Kelvin and Fahrenheit"
            tooltip="Instantly convert between °C, °F and K"
            link="/TemperatureConverter"
            smallCard={true}
            image={TemperatureConverterImage}
          />
        </Grid2>
      </Grid2>
    </SideMenuPageContainer>
  );
}
