import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import {
  calculateTapped,
  heightChanged,
  resultCopyTapped,
  selectState,
  weightChanged,
} from "./BmiCalculatorSlice";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { ResultCard } from "../../components/result_card/ResultCard";
import { CustomButton } from "../../components/custom_button/CustomButton";
import { EqualColumnTable } from "../../components/equal_column_table/EqualColumnTable";
import { SideMenuPageContainer } from "../../components/side_menu_page_container/SideMenuPageContainer";
import Grid2 from "@mui/material/Unstable_Grid2";

export function BmiCalculator() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectState);

  return (
    <SideMenuPageContainer
      pageTitle="BMI Calculator"
      pageRelativeUrl="BmiCalculator"
      pageDescription="Calculate body-mass index by providing height and weight"
    >
      <Grid2
        container
        spacing={2}
        sx={{ mt: 2 }}
        columns={{ xs: 6, sm: 6, md: 12 }}
      >
        <Grid2 xs={12}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Calculate BMI by providing height and weight
          </Typography>
        </Grid2>
        <Grid2 xs={6}>
          <TextField
            fullWidth
            label="Height"
            variant="outlined"
            color="info"
            value={state.heightCm || ""}
            onChange={(e) => dispatch(heightChanged(e.target.value))}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography>cm</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid2>
        <Grid2 xs={6}>
          <TextField
            fullWidth
            label="Weight"
            variant="outlined"
            color="info"
            value={state.weightKg || ""}
            onChange={(e) => dispatch(weightChanged(e.target.value))}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography>kg</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid2>
        <Grid2 xs={12} sx={{mt: 1, mb: 3}}>
        <CustomButton
              label="Calculate BMI"
              onClick={() => {
                dispatch(calculateTapped([state.weightKg, state.heightCm]));
              }}
            />
        </Grid2>
        <Grid2 xs={12}>
        <ResultCard
              value={`${state.bmiResult}`}
              symbol="kg/m²"
              description={state.bmiDescription}
              onCopy={() => dispatch(resultCopyTapped())}
              resultCopied={state.resultCopied}
            />
        </Grid2>
      </Grid2>
    </SideMenuPageContainer>
  );
}
