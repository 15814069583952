import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import "reflect-metadata";
import { container } from "tsyringe";
import { RootState } from "../../app/store";
import { AnalyticsService } from "../../services/analytics";

export enum BaseInputType {
  binary,
  octal,
  decimal,
  hex,
}

export interface BaseConverterState {
  input: string;
  inputType: BaseInputType;
  outputBinary: string;
  outputOctal: string;
  outputDecimal: string;
  outputHex: string;
  binaryResultCopied: boolean;
  octalResultCopied: boolean;
  decimalResultCopied: boolean;
  hexResultCopied: boolean;
  valuePrefix: string;
}

const initialState: BaseConverterState = {
  input: "101",
  inputType: BaseInputType.decimal,
  outputBinary: "1100101",
  outputOctal: "145",
  outputDecimal: "101",
  outputHex: "65",
  binaryResultCopied: false,
  octalResultCopied: false,
  decimalResultCopied: false,
  hexResultCopied: false,
  valuePrefix: "",
};

export const baseConverterSlice = createSlice({
  name: "baseConverter",
  initialState,
  reducers: {
    fromTypeChanged: (state, action: PayloadAction<BaseInputType>) => {
      state.inputType = action.payload;
      state.input = "";

      switch (action.payload) {
        case BaseInputType.binary:
          state.valuePrefix = "0b";
          break;
        case BaseInputType.hex:
          state.valuePrefix = "0x";
          break;
        default:
          state.valuePrefix = "";
          break;
      }
    },
    inputChanged: (state, action: PayloadAction<string>) => {
      const hexRegex = /^[0-9a-fA-F]+$/;

      if (!hexRegex.test(action.payload) && action.payload != '') {
        return;
      }

      state.input = action.payload;
    },
    convertTapped: (state) => {
      const analyticsService = container.resolve(AnalyticsService);
      analyticsService.LogEvent({
        category: "Button Click",
        action: "Convert Clicked",
        label: "Base Converter",
      });
      var inputNumberBase = 0;
      switch (state.inputType) {
        case BaseInputType.binary:
          inputNumberBase = 2;
          break;
        case BaseInputType.octal:
          inputNumberBase = 8;
          break;
        case BaseInputType.decimal:
          inputNumberBase = 10;
          break;
        case BaseInputType.hex:
          inputNumberBase = 16;
          break;
      }
      var number = parseInt(state.input, inputNumberBase);
      state.outputBinary = number.toString(2);
      state.outputOctal = number.toString(8);
      state.outputDecimal = number.toString(10);
      state.outputHex = number.toString(16);
      state.binaryResultCopied = false;
      state.octalResultCopied = false;
      state.decimalResultCopied = false;
      state.hexResultCopied = false;
    },
    binaryResultCopyTapped: (state) => {
      state.binaryResultCopied = true;
      state.octalResultCopied = false;
      state.decimalResultCopied = false;
      state.hexResultCopied = false;
    },
    octalResultCopyTapped: (state) => {
      state.binaryResultCopied = false;
      state.octalResultCopied = true;
      state.decimalResultCopied = false;
      state.hexResultCopied = false;
    },
    decimalResultCopyTapped: (state) => {
      state.binaryResultCopied = false;
      state.octalResultCopied = false;
      state.decimalResultCopied = true;
      state.hexResultCopied = false;
    },
    hexResultCopyTapped: (state) => {
      state.binaryResultCopied = false;
      state.octalResultCopied = false;
      state.decimalResultCopied = false;
      state.hexResultCopied = true;
    },
  },
});

export const selectState = (state: RootState) => state.baseConverter;

export const {
  fromTypeChanged,
  inputChanged,
  convertTapped,
  binaryResultCopyTapped,
  octalResultCopyTapped,
  decimalResultCopyTapped,
  hexResultCopyTapped,
} = baseConverterSlice.actions;

export default baseConverterSlice.reducer;
