import { MenuItem, SelectChangeEvent } from "@mui/material";
import { CustomSelect } from "../custom_select/CustomSelect";

interface CustomInputProperties {
    label: string;
    value: any | undefined;
    onChange?: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
    children?: React.ReactNode;
  }
  
  export function CurrencySelect(properties: CustomInputProperties) {
    return (
        <CustomSelect
            label={properties.label}
            value={properties.value}
            onChange={properties.onChange}
          >
            <MenuItem value="$">$</MenuItem>
            <MenuItem value="€">€</MenuItem>
            <MenuItem value="£">£</MenuItem>
            <MenuItem value="¥">¥</MenuItem>
            <MenuItem value="CHF">CHF</MenuItem>
            <MenuItem value="₣">₣</MenuItem>
            <MenuItem value="₹">₹</MenuItem>
            <MenuItem value="د.ك">د.ك</MenuItem>
            <MenuItem value="د.إ">د.إ</MenuItem>
            <MenuItem value="¢">¢</MenuItem>
            <MenuItem value="kr">kr</MenuItem>
            <MenuItem value="﷼">﷼</MenuItem>
            <MenuItem value="₩">₩</MenuItem>
            <MenuItem value="₻">₻</MenuItem>
            <MenuItem value="₽">₽</MenuItem>
            <MenuItem value="₾">₾</MenuItem>
            <MenuItem value="₺">₺</MenuItem>
            <MenuItem value="₼">₼</MenuItem>
            <MenuItem value="₸">₸</MenuItem>
            <MenuItem value="₴">₴</MenuItem>
            <MenuItem value="₷">₷</MenuItem>
            <MenuItem value="฿">฿</MenuItem>
            <MenuItem value="원">원</MenuItem>
            <MenuItem value="₫">₫</MenuItem>
            <MenuItem value="₮">₮</MenuItem>
            <MenuItem value="₯">₯</MenuItem>
            <MenuItem value="₱">₱</MenuItem>
            <MenuItem value="₳">₳</MenuItem>
            <MenuItem value="₵">₵</MenuItem>
            <MenuItem value="₲">₲</MenuItem>
            <MenuItem value="₪">₪</MenuItem>
            <MenuItem value="₰">₰</MenuItem>
            <MenuItem value="R">R</MenuItem>
          </CustomSelect>
    );
  }