import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { container } from "tsyringe";
import { RootState } from "../../app/store";
import { AnalyticsService } from "../../services/analytics";
import { PngIcoConverter } from "../../utilities/pngtoicojs";

export interface FaviconGeneratorState {
  codesnippetCopied: boolean;
}

const initialState: FaviconGeneratorState = {
  codesnippetCopied: false,
};

export const FaviconGeneratorSlice = createSlice({
  name: "faviconGenerator",
  initialState,
  reducers: {
    codeSnippetCopied(state, action: PayloadAction<boolean>) {
      state.codesnippetCopied = action.payload;
    },
  },
});

export const pngToFaviconAsync = createAsyncThunk(
  "faviconGenerator/pngToFaviconAsync",
  async (file: File | undefined) => {
    const analyticsService = container.resolve(AnalyticsService);
    analyticsService.LogEvent({
      category: "Button Click",
      action: "Convert Clicked",
      label: "Favicon Generator",
    });
    if (file === undefined) return;

    await import("react-image-file-resizer").then(async (FileResizerModule) => {
      const FileResizer = FileResizerModule.default;

      const resizePng = (file: File, size: number) =>
        new Promise((resolve) => {
          FileResizer.imageFileResizer(
            file,
            size,
            size,
            "PNG",
            100,
            0,
            (uri) => {
              resolve(uri);
            },
            "file"
          );
        });

      const png16 = (await resizePng(file, 16)) as File;
      const png24 = (await resizePng(file, 24)) as File;
      const png32 = (await resizePng(file, 32)) as File;
      const png48 = (await resizePng(file, 48)) as File;
      const png64 = (await resizePng(file, 64)) as File;
      const png152 = (await resizePng(file, 152)) as File;
      const png167 = (await resizePng(file, 167)) as File;
      const png180 = (await resizePng(file, 180)) as File;
      const png192 = (await resizePng(file, 192)) as File;
      const png512 = (await resizePng(file, 512)) as File;

      const icoFiles = [png16, png24, png32, png64];
      const inputItems = await Promise.all(
        icoFiles.map(async (f) => {
          const pngContent = await readFileAsArrayBuffer(f);
          return { png: pngContent };
        })
      );

      try {
        const manifestJsonBlob = new Blob([manifestJsonString], {
          type: "application/json",
        });

        var blob = await pngConverter.convertToBlobAsync(inputItems);

        await import("jszip").then(async (JSZipModule) => {
          const JSZip = JSZipModule.default;
          const zip = new JSZip();

          zip.file("favicon.ico", blob);
          zip.file("favicon-32x32.png", png32);
          zip.file("favicon-16x16.png", png16);
          zip.file("favicon-48x48.png", png48);
          zip.file("favicon-192x192.png", png192);
          zip.file("favicon-152x152.png", png152);
          zip.file("favicon-167x167.png", png167);
          zip.file("favicon-180x180.png", png180);
          zip.file("favicon-512x512.png", png512);
          zip.file("manifest.json", manifestJsonBlob);

          var zipBlob = await zip.generateAsync({ type: "blob" });

          // Create a download link
          const url = window.URL.createObjectURL(zipBlob);
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.href = url;
          a.download = "favicon.zip";
          a.click();
          window.URL.revokeObjectURL(url);
        });
      } catch (error) {
        console.error("Error during conversion", error);
      }
    });
  }
);

export const { codeSnippetCopied } = FaviconGeneratorSlice.actions;

export const selectState = (state: RootState) => state.faviconGenerator;

export default FaviconGeneratorSlice.reducer;

function readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as ArrayBuffer);
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}

const pngConverter = new PngIcoConverter();

const manifestJsonString = `{
    "short_name": "My App",
    "name": "My App Long Name",
    "description": "App description.",
    "icons": [
        {
            "src": "favicon.ico",
            "sizes": "64x64 32x32 24x24 16x16",
            "type": "image/x-icon"
        },
        {
            "src": "favicon-48x48.png",
            "type": "image/png",
            "sizes": "48x48"
        },
        {
            "src": "favicon-192x192.png",
            "type": "image/png",
            "sizes": "192x192"
        },
        {
            "src": "favicon-512x512.png",
            "type": "image/png",
            "sizes": "512x512"
        }
    ],
    "start_url": ".",
    "display": "standalone",
    "theme_color": "#000000",
    "background_color": "#ffffff"
}`;
