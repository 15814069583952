import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { PageContainer } from "../../components/page_container/PageContainer";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  downloadClicked,
  hdpiValueCopiedTapped,
  includeAndroidImagesOnChanged,
  includeIosImagesOnChanged,
  maxResolutionHeightOnChanged,
  maxResolutionWidthOnChanged,
  mdpiValueCopiedTapped,
  selectFileAsync,
  selectState,
  xhdpiValueCopiedTapped,
  xxhdpiValueCopiedTapped,
  xxxhdpiValueCopiedTapped,
} from "./AndroidIosImageResizerSlice";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { CustomButton } from "../../components/custom_button/CustomButton";
import CloudUploadIcon from "@mui/icons-material/CloudUploadOutlined";
import ImageIcon from "@mui/icons-material/ImageOutlined";
import FileSaveIcon from "@mui/icons-material/SaveOutlined";
import { SideMenuPageContainer } from "../../components/side_menu_page_container/SideMenuPageContainer";
import { ResultCard } from "../../components/result_card/ResultCard";
import { HomeCard } from "../../components/home_card/HomeCard";
import FaviconGeneratorImage from "../../assets/FaviconGenerator.webp";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export function AndroidIosImageResizer() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectState);

  return (
    <SideMenuPageContainer
      pageTitle="Free app Image Resizer for Android and iOS | Resize Images for All Screen Densities"
      pageRelativeUrl="AndroidIosImageResizer"
      pageDescription="Resize Android and iOS app image assets for all screen densities by the click of a button"
    >
      <Grid2
        container
        spacing={2}
        sx={{ mt: 2 }}
        columns={{ xs: 6, sm: 6, md: 12 }}
      >
        <Grid2 xs={12}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Generate Android and iOS App Image Assets
          </Typography>
        </Grid2>
        <Grid2 xs={12}>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked={true}
                    value={state.includeAndroidImages}
                    onChange={(e) => {
                      dispatch(
                        includeAndroidImagesOnChanged(Boolean(e.target.checked))
                      );
                      console.log(e.target.checked);
                    }}
                  />
                }
                label="Android Images"
              />
              <FormControlLabel
                control={
                  <Switch
                    value={state.includeIosImages}
                    onChange={(e) => {
                      dispatch(
                        includeIosImagesOnChanged(Boolean(e.target.checked))
                      );
                    }}
                  />
                }
                label="iOS Images"
              />
            </FormGroup>
          </Stack>
        </Grid2>

        <Grid2 xs={12}>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Button
              component="label"
              variant="contained"
              sx={{ width: 180 }}
              startIcon={<CloudUploadIcon sx={{ color: "#FFD9A8" }} />}
              onChange={(e: any) => {}}
            >
              <Typography>Select PNG</Typography>
              <VisuallyHiddenInput
                type="file"
                accept="image/png"
                onChange={(e: any) =>
                  dispatch(selectFileAsync(e.target.files[0]))
                }
              />
            </Button>
          </Stack>
        </Grid2>

        <Grid2
          xs={12}
          sx={{ display: state.imageSelected ? "inline" : "none" }}
        >
          <Stack direction="column" justifyContent="center" alignItems="center">
            <ImageIcon sx={{ fontSize: "100px" }} />
            <Typography>{state.imageFileName}</Typography>
          </Stack>
        </Grid2>

        <Grid2
          xs={12}
          sx={{ display: state.imageSelected ? "inline" : "none" }}
        >
          <Stack direction="column" justifyContent="center" alignItems="center">
            <TextField
              label="Max Resolution Height"
              variant="outlined"
              color="info"
              value={state.maxResolutionHeight}
              onChange={(e) => {
                dispatch(maxResolutionHeightOnChanged(e.target.value));
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography>px</Typography>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </Grid2>
        <Grid2
          xs={12}
          sx={{ display: state.imageSelected ? "inline" : "none" }}
        >
          <Stack direction="column" justifyContent="center" alignItems="center">
            <TextField
              label="Max Resolution Width"
              variant="outlined"
              color="info"
              value={state.maxResolutionWidth}
              onChange={(e) => {
                dispatch(maxResolutionWidthOnChanged(e.target.value));
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography>px</Typography>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </Grid2>

        <Grid2 xs={12}>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Box sx={{ mt: 3 }}>
              <CustomButton
                icon={<FileSaveIcon sx={{ color: "#FFD9A8" }} />}
                disabled={
                  !state.imageSelected ||
                  (!state.includeAndroidImages && !state.includeIosImages)
                }
                label="Resize"
                width={180}
                onClick={() => {
                  dispatch(downloadClicked());
                }}
              />
            </Box>
          </Stack>
        </Grid2>
        <Grid2 xs={12}>
          <Typography variant="h3" sx={{ mt: 5 }}>
            Achieve Performance Without Compromising on Quality
          </Typography>
          <Typography variant="body1" sx={{ mt: 3, mb: 3 }}>
            Our free tool helps you resize your Android and iOS app images for
            all screen densities. It is designed to help you optimize your app's
            performance by ensuring that your images are optimized for all
            devices. With this Image Resizer, you can easily resize your images
            to the perfect size for your app, without having to worry about
            pixelation or distortion.
          </Typography>
          <Typography variant="h3" sx={{ mt: 3 }}>
            Supporting All Android Display Pixel Densities
          </Typography>
          <Typography variant="body1" sx={{ mt: 3, mb: 3 }}>
            To enhance the visual experience for your users{" "}
            <a
              href="https://developer.android.com/training/multiscreen/screendensities"
              title="Android's official guidelines on image assets for multiple screen densities"
            >
              {" "}
              on Android devices
            </a>
            , it's essential to accommodate various screen pixel densities by
            supplying images in multiple resolutions. When only a single
            resolution is provided, Android is compelled to scale your image to
            fit other densities, which can lead to suboptimal performance or
            unsightly blurry artifacts. Manually resizing every image in your
            app can be a laborious and time-consuming process. This is precisely
            where our tool becomes invaluable, simplifying and streamlining the
            task of image resizing, ensuring crisp and clear visuals across all
            device types.
          </Typography>
          <ResultCard
            value={`Scaling Factor: 1x  ~160 DPI`}
            smallResult={true}
            description="MDPI"
            symbol={""}
            resultCopied={state.mdpiValueCopied}
            onCopy={() => {
              dispatch(mdpiValueCopiedTapped());
            }}
          />
          <ResultCard
            value={`Scaling Factor: 1.5x  ~240 DPI`}
            smallResult={true}
            description="HDPI"
            symbol={""}
            resultCopied={state.hdpiValueCopied}
            onCopy={() => {
              dispatch(hdpiValueCopiedTapped());
            }}
          />
          <ResultCard
            value={`Scaling Factor: 2x  ~320 DPI`}
            smallResult={true}
            description="XHDPI"
            symbol={""}
            resultCopied={state.xhdpiValueCopied}
            onCopy={() => {
              dispatch(xhdpiValueCopiedTapped());
            }}
          />
          <ResultCard
            value={`Scaling Factor: 3x  ~480 DPI`}
            smallResult={true}
            description="XXHDPI"
            symbol={""}
            resultCopied={state.xxhdpiValueCopied}
            onCopy={() => {
              dispatch(xxhdpiValueCopiedTapped());
            }}
          />
          <ResultCard
            value={`Scaling Factor: 4x  ~640 DPI`}
            smallResult={true}
            description="XXXHDPI"
            symbol={""}
            resultCopied={state.xxxhdpiValueCopied}
            onCopy={() => {
              dispatch(xxxhdpiValueCopiedTapped());
            }}
          />
          <Typography variant="h3" sx={{ mt: 3 }}>
            Get a Crisp and Clear Image on iOS
          </Typography>
          <Typography variant="body1" sx={{ mt: 3, mb: 3 }}>
            <a
              href="https://developer.apple.com/design/human-interface-guidelines/images"
              title="Apple's official guidelines on image assets"
            >
              In iOS app development
            </a>
            , it's crucial to provide images in three key resolutions to ensure
            optimal display across various Apple devices. These include the
            standard resolution (@1x), which is the baseline for older or
            lower-resolution devices. The @2x resolution is for Retina displays,
            which have twice the pixel density of standard displays, effectively
            doubling the resolution for sharper images. Finally, the @3x
            resolution caters to devices with even higher pixel densities, like
            some models of the iPhone and iPad, offering an even more refined
            and detailed visual experience. By incorporating these three
            resolutions, developers can guarantee that their app's visuals are
            crisp and clear, regardless of the device's screen specifications,
            thus enhancing the user experience.
          </Typography>
          <Typography variant="h3" sx={{ mt: 3 }}>
            Looking for a Web App Image Resizer Instead?
          </Typography>
          <Typography variant="body1" sx={{ mt: 3, mb: 3 }}>
            Use our{" "}
            <a
              href="/FaviconGenerator"
              title="PNG to Favicon and Manifest Converter"
            >
              free Favicon and Web Manifest generator
            </a>{" "}
            to generate favicons for your website or web application.
          </Typography>
        </Grid2>
        <Grid2 xs={12}>
          <Typography variant="h3" sx={{ mt: 3, mb: 1 }}>
            Related
          </Typography>
          <Grid2 xs={3}>
            <HomeCard
              title="Favicon Generator"
              description="Generate a Website favicon and manifest.json for multiple resolutions to achieve maximum browser support"
              tooltip="Free online PNG to favicon and manifest.json converter"
              link="/FaviconGenerator"
              smallCard={true}
              image={FaviconGeneratorImage}
            />
          </Grid2>
        </Grid2>
      </Grid2>
    </SideMenuPageContainer>
  );
}
