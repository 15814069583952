import { ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Link } from "react-router-dom";

interface CustomInputProperties {
    label: string;
    link: string;
    icon?: React.ReactNode;
    onClick: () => void;
  }

  export function SideMenuLink(properties: CustomInputProperties) {
    return (
        <Link
          to={properties.link}
          style={{ textDecorationColor: "transparent" }}
        >
          <ListItemButton
            onClick={() => {
                properties.onClick();
            }}
          >
            <ListItemIcon>
                {properties.icon}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="menulink" sx={{
                  textOverflow: "ellipsis",
                  WebkitLineClamp: 1,
                  lineClamp: 1,
                  overflow: "hidden",
                  wordBreak: "break-all",
                  WebkitBoxOrient: "vertical",
                  display: "-webkit-box"
              }}>
                  {properties.label}
                </Typography>
              }
            />
          </ListItemButton>
        </Link>
    );
  }