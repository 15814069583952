import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { container } from "tsyringe";
import { RootState } from '../../app/store';
import { AnalyticsService } from "../../services/analytics";
import { round } from '../../utilities/maths';

export interface BmiCalculatorState {
    heightCm: number;
    weightKg: number;
    bmiResult: number;
    resultCopied: boolean;
    bmiDescription: string;
  }
  
  const initialState: BmiCalculatorState = {
    heightCm: 170,
    weightKg: 65,
    bmiResult: 22.49,
    resultCopied: false,
    bmiDescription: 'Normal',
  };
  

  export const bmiCalculatorSlice = createSlice({
    name: 'bmiCalculator',
    initialState,
    reducers: {
      heightChanged: (state, action: PayloadAction<string>) => {
        console.log(action.payload);
        if (Number.isNaN(Number(action.payload)) && action.payload != '') {
          return;
        }
  
        state.heightCm = Number(action.payload);
      },
      weightChanged: (state, action: PayloadAction<string>) => {
        if (Number.isNaN(Number(action.payload)) && action.payload != '') {
          return;
        }
  
        state.weightKg = Number(action.payload);
      },
      calculateTapped: (state, action: PayloadAction<number[]>) => {
        const analyticsService = container.resolve(AnalyticsService);
        analyticsService.LogEvent({    
        category: 'Button Click',
        action: 'Convert Clicked',
        label: 'BMI Calculator',
        });

        var weightKg = action.payload[0];
        var heightM = action.payload[1] / 100;
        var bmiResult = round(weightKg / (heightM * heightM),2);
        state.bmiResult = bmiResult;
        state.resultCopied = false;

        if (bmiResult <= 16) {
          state.bmiDescription = "Severe Thinness";
        } else if (bmiResult <= 17) {
          state.bmiDescription = "Moderate Thinness";
        } else if (bmiResult <= 18.5) {
          state.bmiDescription = "Mild Thinness";
        } else if (bmiResult <= 25) {
          state.bmiDescription = "Normal";
        } else if (bmiResult <= 30) {
          state.bmiDescription = "Overweight";
        } else if (bmiResult <= 35) {
          state.bmiDescription = "Obese Class I";
        } else if (bmiResult <= 40) {
          state.bmiDescription = "Obese Class II";
        } else if (bmiResult > 40) {
          state.bmiDescription = "Obese Class III";
        }
      },
      resultCopyTapped: (state) => {
        state.resultCopied = true;
      },
    },
  });

  export const {heightChanged, weightChanged, calculateTapped, resultCopyTapped } = bmiCalculatorSlice.actions;

  export const selectState = (state: RootState) => state.bmiCalculator;

  export default bmiCalculatorSlice.reducer;
