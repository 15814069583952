import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface HomeState {
}

const initialState: HomeState = {
}

export const HomeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
    }
});

export const { } = HomeSlice.actions;

export const selectState = (state: RootState) => state.home;

export default HomeSlice.reducer;