import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { container } from "tsyringe";
import { RootState } from '../../app/store';
import { AnalyticsService } from "../../services/analytics";
import { round } from '../../utilities/maths';

export enum TemperatureUnitType {
    celcius,
    farenheit,
    kelvin,
}

export interface TemperatureConverterState {
    inputValue: number;
    inputUnit: TemperatureUnitType;
    outputUnit: TemperatureUnitType;
    resultUnit: TemperatureUnitType;
    outputValue: number;
    resultCopied: boolean;
    resultSymbol: string;
    inputSymbol: string;
}

const initialState: TemperatureConverterState = {
    inputValue: 50,
    inputUnit: TemperatureUnitType.farenheit,
    outputUnit: TemperatureUnitType.celcius,
    resultUnit: TemperatureUnitType.celcius,
    outputValue: 10,
    resultCopied: false,
    resultSymbol: "°C",
    inputSymbol: "°F",
}

export const TemperatureConverterSlice = createSlice({
    name: 'temperatureConverter',
    initialState,
    reducers: {
      inputValueOnChanged: (state, action: PayloadAction<number>) => {
        state.inputValue = action.payload;
      },
      inputTypeOnChanged: (state, action: PayloadAction<TemperatureUnitType>) => {
        state.inputUnit = action.payload;
        state.inputSymbol = getSymbol(action.payload);
      },
      outputTypeOnChanged: (state, action: PayloadAction<TemperatureUnitType>) => {
        state.outputUnit = action.payload;
      },
      calculateTapped: (state) => {
        const analyticsService = container.resolve(AnalyticsService);
        analyticsService.LogEvent({    
            category: 'Button Click',
            action: 'Convert Clicked',
            label: 'Temperature Unit Converter',
        });

        var result = 0.00;
        switch(state.inputUnit){
            case TemperatureUnitType.farenheit:
                result = farenheitConversions(state.inputValue, state.outputUnit);
                break;
            case TemperatureUnitType.celcius:
                result = celciusConversions(state.inputValue, state.outputUnit);
                break;
            case TemperatureUnitType.kelvin:
                result = kelvinConversions(state.inputValue, state.outputUnit);
                break;
        }
        state.outputValue = result;

        state.resultSymbol = getSymbol(state.outputUnit);
        state.resultUnit = state.outputUnit;
        state.resultCopied = false;
      },
      resultCopyTapped: (state) => {
        state.resultCopied = true;
      },
    }
});

export const {calculateTapped, inputTypeOnChanged, outputTypeOnChanged, inputValueOnChanged, resultCopyTapped } = TemperatureConverterSlice.actions;

export const selectState = (state: RootState) => state.temperatureConverter;

export default TemperatureConverterSlice.reducer;

function farenheitConversions(inputValue: number, outputType: TemperatureUnitType) {
    var outputValue = 0;
    switch(outputType){
        case TemperatureUnitType.farenheit:
            outputValue = inputValue;
            break;
        case TemperatureUnitType.celcius:
            outputValue = (inputValue-32)*(5/9);
            break;
        case TemperatureUnitType.kelvin:
            outputValue = (inputValue-32)*(5/9) + 273.15;
            break;
    }
    return round(outputValue,2);
}

function celciusConversions(inputValue: number, outputType: TemperatureUnitType) {
    var outputValue = 0;
    switch(outputType){
        case TemperatureUnitType.farenheit:
            outputValue = inputValue*(9/5)+32;
            break;
        case TemperatureUnitType.celcius:
            outputValue = inputValue;
            break;
        case TemperatureUnitType.kelvin:
            outputValue = inputValue + 273.15;
            break;
    }
    return round(outputValue,2);
}

function kelvinConversions(inputValue: number, outputType: TemperatureUnitType) {
    var outputValue = 0;
    switch(outputType){
        case TemperatureUnitType.farenheit:
            outputValue = (inputValue-273.15)*(9/5)+32;
            break;
        case TemperatureUnitType.celcius:
            outputValue = inputValue - 273.15;
            break;
        case TemperatureUnitType.kelvin:
            outputValue = inputValue;
            break;
    }
    return round(outputValue,2);
}

function getSymbol(temperatureUnitType: TemperatureUnitType)
{
    switch (temperatureUnitType){
        case TemperatureUnitType.farenheit:
            return "°F";
        case TemperatureUnitType.celcius:
            return "°C";
        case TemperatureUnitType.kelvin:
            return "K";
        default:
            return "";
    }
}