import { Container } from "@mui/material";

import {
  Route,
  RouterProvider,
  Routes,
  ScrollRestoration,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import "reflect-metadata";
import { container } from "tsyringe";
import "./App.css";
import { Home } from "./features/home/Home";
import { BaseConverter } from "./features/base_converter/BaseConverter";
import { BmiCalculator } from "./features/bmi_calculator/BmiCalculator";
import { CompoundInterestCalculator } from "./features/compound_interest_calculator/CompoundInterestCalculator";
import { CreditCardRepaymentCalculator } from "./features/credit_card_repayment_calculator/CreditCardRepaymentCalculator";
import { DistanceUnitConverter } from "./features/distance_unit_converter/DistanceUnitConverter";
import { FaviconGenerator } from "./features/favicon_generator/FaviconGenerator";
import { TemperatureConverter } from "./features/temperature_converter/TemperatureConverter";
import { WeightUnitConverter } from "./features/weight_unit_converter/WeightUnitConverter";

import { AnalyticsService } from "./services/analytics";
import { AndroidIosImageResizer } from "./features/android_ios_image_resizer/AndroidIosImageResizer";

export function App() {
  const analyticsService = container.resolve(AnalyticsService);
  analyticsService.Initialize("G-VECM5N4S2Q");

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<Home />} />
        <Route path="/BaseConverter" element={<BaseConverter />} />
        <Route path="/BmiCalculator" element={<BmiCalculator />} />
        <Route
          path="/DistanceUnitConverter"
          element={<DistanceUnitConverter />}
        />
        <Route path="/WeightUnitConverter" element={<WeightUnitConverter />} />
        <Route path="/FaviconGenerator" element={<FaviconGenerator />} />
        <Route
          path="/TemperatureConverter"
          element={<TemperatureConverter />}
        />
        <Route
          path="/CompoundInterestCalculator"
          element={<CompoundInterestCalculator />}
        />
        <Route
          path="/CreditCardRepaymentCalculator"
          element={<CreditCardRepaymentCalculator />}
        />
        <Route
          path="/AndroidIosImageResizer"
          element={<AndroidIosImageResizer />}
        />
      </>
    )
  );

  return (
    <Container>
      <RouterProvider router={router} />
    </Container>
  );
}

export default App;
